import { RegistrationCard } from "@component";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { themeColors } from "Themes/ThemeColors";
import { useCreateServiceProviderProfile } from "hooks";
import { passResetReqSchema } from "pages";

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { createAnEmail, resetPassword } from "services";
import { notify } from "shared";
import { useFormStore } from "store";
import { ICreateEmailType, IResetPasswordType } from "types";
import { Button } from "ui/button";
import { OtpInput } from "ui/otpInput";
import { isAuthenticated, setRefreshToken, setToken } from "utils/auth/auth";

export const ConfirmOtp = ({
  openOtp,
  files,
  secretKey,
  onClose,
  setSecretKey,
  //   setOtpModal,
  //   onSubmit,
  //   phone,
  //   handleSignUp,
  //   requestOTPMethod,
  //   type,
}: {
  setOtpModal: (v: string) => void;
  files: any[];
  setSecretKey: (data: string) => void;
  secretKey: string;
  //   onSubmit: (v: any) => void;
  //   phone?: string;
  //   handleSignUp: (v: boolean) => void;
  isLoading: boolean;
  onClose: (term: string) => void;
  //   requestOTPMethod: any;
  openOtp: string;
  //   type?: string;
}) => {
  // const [otpModal, setOtpModal] = useState<boolean>(false);
  const [timeSpan, setTimeSpan] = useState<number>(0);
  const [interval, setCountdown] = useState<boolean>(false);
  const [loadingOtp, setOtpLoading] = useState<boolean>(false);
  const { createServiceProviderMutation, isCreatingServiceProvider } =
    useCreateServiceProviderProfile();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const selected = useFormStore((state) => state.selected);

  const formPayload = selected.data;

  const { mutateAsync: emailMutate, isPending: isLoading } = useMutation({
    mutationFn: async (payload: ICreateEmailType) =>
      await createAnEmail({ ...payload }),
    onSuccess: async (response) => {
      // setOpenSignUp(false);
      setSecretKey(response?.data);
      // setOpenConfirmPassword(true);
      notify.success({
        message: "Success",
        subtitle: "sent successfully",
      });
    },
    onError: async ({ response }: any) => {
      notify.error({
        message: "Error",
        subtitle: response?.data?.error || "",
      });
    },
  });

  const formatTime = (milliseconds: number): string => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `in ${formattedMinutes}:${formattedSeconds}`;
  };

  const otpMethod = useForm({
    mode: "onChange",
    resolver: yupResolver(passResetReqSchema),
    // defaultValues,
  });

  const { email } = otpMethod.watch();

  const { mutate: resendOTP, isPending: isFetching } = useMutation({
    mutationFn: async (payload: IResetPasswordType) =>
      await resetPassword({ ...payload }),
    onSuccess: async (response) => {
      //   setOtpModal("confirm-password");
      // setOpenForgetPassword("");
      // setSecretKey(response?.data);
      notify.success({
        message: "Successful",
        subtitle: response?.message,
      });
      // navigate("/admin");
    },
    onError: async ({ response }: any) => {
      notify.error({
        message: "Error",
        subtitle: response?.data?.error,
      });
      // setOtpModal(false);
    },
  });

  const handleResendOtp = async () => {
    // handleSignUp(false);

    if (timeSpan === 0) {
      setOtpLoading(true);
      setCountdown(!interval);
    }
    // resendOTP({
    //   email: formPayload?.email,
    // });

    await emailMutate({
      email: formPayload?.email,
    });
  };

  useEffect(() => {
    let timerInterval: any;
    if (openOtp) {
      const startTime = Date.now();
      const endTime = new Date(startTime);
      endTime.setMinutes(endTime.getMinutes() + 2);

      const calculateTimeSpan = () => {
        const remainingTime = endTime.getTime() - Date.now();
        setTimeSpan(() => Math.max(0, remainingTime));
      };

      calculateTimeSpan();

      timerInterval = setInterval(() => {
        calculateTimeSpan();
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [interval, openOtp]);

  const onFormSubmit = async () => {
    const formData = new FormData();

    formData.append("profile_picture", formPayload?.pictures[0]);
    formPayload?.skills.forEach((item: any, index: any) => {
      formData.append(`skill_data[${index}]skill`, item.skills);
      formData.append(`skill_data[${index}]cost`, `${item.cost}`);
      formData.append(`skill_data[${index}]time_frame`, `${item.time_frame}`);
    });
    formData.append("skill_data", JSON.stringify(formPayload.skills));
    formData.append("images", files[0]);
    formData.append("business_name", formPayload.name);
    formData.append("country", formPayload.country);
    formData.append("state", formPayload.state);
    formData.append("city", formPayload.state);
    formData.append("phone", formPayload?.phoneNumber);
    formData.append("zip_code", formPayload?.zipCode);
    formData.append("about_me", formPayload?.zipCode);
    formData.append("email", formPayload?.email);
    formData.append("password", formPayload?.password);
    formData.append("otp", email);
    formData.append("secret_key", secretKey);
    formData.append("is_active", "true");

    // setOtpModal("confirmOtp");

    const res = await createServiceProviderMutation(formData);

    // console.log(res, "response form service profile");

    if (res?.success) {
      setToken(res?.data?.token?.access);
      setRefreshToken(res?.data?.token?.refresh);

      await queryClient.refetchQueries({
        queryKey: ["get-user-profile"],
      });

      isAuthenticated();

      notify.success({
        subtitle: "Successfully created",
        message: "Please update your profile",
      });

      navigate("/profile");
    }
  };

  return (
    <RegistrationCard
      show={openOtp === "confirmOtp"}
      onClose={() => onClose("")}
    >
      <div className="w-full flex flex-col  items-center gap-2">
        <span className="text-xl font-bold">Confirm your Email Address</span>
        <span className="text-center text-sm">
          We have sent an OTP to your email,
        </span>
      </div>
      <FormProvider {...otpMethod}>
        <form className="mt-6 w-full items-center flex flex-col ">
          <OtpInput
            name="otp"
            length={6}
            // size="md"
            type="number"
            onChange={(value: any) => otpMethod.setValue("email", value)}
            className="my-4"
          />
        </form>
      </FormProvider>
      <div>
        <span className="text-sm my-3 cursor-pointer" onClick={handleResendOtp}>
          Didn’t receive a code? Resend {timeSpan !== 0 && formatTime(timeSpan)}
        </span>
      </div>
      <Button
        className="mt-5 w-full font-normal"
        color={themeColors.navbarBtnBlue}
        style={{ width: "100%" }}
        type="submit"
        loading={isCreatingServiceProvider}
        onClick={onFormSubmit}
      >
        {/* {timeSpan === 0
          ? "Resend OTP Verification"
          : "Confirm OTP Verification"} */}
        Confirm OTP Verification
      </Button>
    </RegistrationCard>
  );
};
