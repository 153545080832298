import { ICategoryResponseData, IFavoriteResponseData } from "types/favorite";
import Api from "utils/auth/api";
import { IQueryParams, queryParamsHelper } from "./query-params";

export const getFavoriteServiceFn = async () => {
  return await Api.get<IFavoriteResponseData>("/service/favorites/").then(
    (res) => res.data
  );
};
export const getServiceFn = async () => {
  return await Api.get<IFavoriteResponseData>("/service/favorites/").then(
    (res) => res.data
  );
};
export const getCategories = async ({ queryKey }: IQueryParams) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);

  return await Api.get<ICategoryResponseData>(`/category/${queryParams}`).then(
    (res) => res.data
  );
};
