import { useState } from "react";
import ChatList from "./ChatList";
import CustomAvatar from "./CustomAvatar";
import SearchIcon from "./SearchIcon";
import { useAuthenticatedUser } from "hooks";
import { useGetConvoList } from "hooks/services/useGetConvoList";

const msg = [
  {
    name: "Olatubora Felix",
    content: "I am doing great",
    id: 1,
  },
  {
    name: "Donatus Amaka",
    content: "Yea, I would love that",
    id: 2,
  },
  {
    name: "Olayemi Olaniran",
    content: "All is well",
    id: 3,
  },
  {
    name: "Brian Huston",
    content: "A cup of tea, aint bad",
    id: 4,
  },

  {
    name: "Jadon Sancho",
    content: "Let's sign the deal",
    id: 5,
  },
  {
    name: "Kolapo Ale",
    content: "A cup of tea, aint bad",
    id: 6,
  },
  {
    name: "Damaris Titus",
    content: "Let's meet at the club",
    id: 7,
  },
  {
    name: "Joseph Prince",
    content: "Prolonging his days on earth",
    id: 8,
  },
  {
    name: "Nicolas Adam",
    content: "The meeting is postponed",
    id: 9,
  },
  {
    name: "Anita Baby",
    content: "Ya such a darling",
    id: 10,
  },
];

const MobileMessage = () => {
  const [term, setTerm] = useState("");
  const { userDetails } = useAuthenticatedUser();
  const { allChatList } = useGetConvoList();

  const chattersList = allChatList?.results?.map((item) => ({
    fullname: item?.recipient?.fullname,
    profilePic: item?.recipient?.profile_picture,
    id: item?.id,
    content: item?.last_message_content,
    timestamp: item?.last_message_time,
  }));

  return (
    <div>
      <h1 className="text-base font-normal text-accents-800 p-[16px_14px_12px_14px]">
        {"message"}
      </h1>
      <div className="bg-accents-300 px-4 py-4 ">
        <div className="bg-accents-100 rounded-lg">
          <div className="flex items-center gap-2 p-3 border-b-accents-300 border ">
            {/* <Image
              src="/images/profile.svg"
              alt="Profile image"
              height={0}
              width={0}
              className="h-8 w-8 object-cover rounded-full"
            /> */}

            <CustomAvatar
              username={userDetails?.data?.fullname}
              src={userDetails?.data?.profile_picture}
            />

            <div className="flex flex-col">
              <span className="text-xs">{userDetails?.data?.fullname}</span>

              {/* <span className="text-xs">{t("me")}</span> */}
              <p className="flex items-center gap-1 text-xs">
                <span className="text-accents-700">{"online"}</span>
                <span>{/* <GreenDot /> */}</span>
              </p>
            </div>
          </div>
          <div className="bg-accents-100">
            <div className="bg-accents-100">
              <div
                className={`flex  w-full items-center rounded-[9px] border border-dark-light px-[16px]  relative  bg-accents-100`}
              >
                <SearchIcon className=" right-4 bg-transparent text-white bg-white" />

                <input
                  type="text"
                  onChange={(e) => setTerm(e.target.value)}
                  value={term}
                  className={`w-full borde border-accents-500 bg-accents-100 px-3 py-2 text-md  rounded-lg file:text-sm file:font-medium placeholder:text-accents-500 placeholder:text-[10px] md:placeholder:text-sm focus-visible:outline-none focus-visible:ring-primary focus-visible:ring-0 dark:focus-visible:ring-primary dark:placeholder:text-accents-500 disable:opacity-50 disabled:cursor-not-allowed h-10 flex file:border-0`}
                />
              </div>
            </div>
          </div>
          <div>
            {chattersList?.map((item) => (
              <ChatList
                key={item.id}
                sellername={item.fullname}
                content={item?.content}
                timestamp={item?.timestamp}
                id={item?.id}
                // imgUrl={item?.imgUrl}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMessage;
