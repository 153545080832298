// import { useForm } from "react-hook-form";

import DesktopMessage from "./DesktopMessage";
import MobileMessage from "./MobileMessage";
import { useSocket } from "hooks/profile/useSocket";

export const Chat = () => {
  // const form = useForm();
  useSocket();
  return (
    <div className="w-full shadow-xl border-[.1px] rounded-lg  container mt-[70px] p-4">
      <div className="md:hidden">
        <MobileMessage />
      </div>
      <div className="hidden md:block bg-accents-300 pb-10">
        <DesktopMessage />
      </div>
    </div>
  );
};
