import React from "react";
import CustomAvatar from "./CustomAvatar";
import { Link } from "react-router-dom";
import { formatTime } from "utils";

const ChatList = ({
  id,
  sellername,
  content,
  timestamp,
}: {
  id: any;
  sellername?: string;
  content?: string;
  timestamp?: string;
}) => {
  return (
    <Link to={`/chat/${id}`}>
      <div className="flex items-center justify-between gap-2 p-[14px_12px]  ">
        <div className="flex items-center gap-1">
          <div className="relative ">
            <CustomAvatar username={sellername || "Test"} src={""} />
            <span className="absolute top-1 right-0">{/* <GreenDot /> */}</span>
          </div>

          <div className="flex flex-col">
            <span className="text-xs font-normal text-accents-800">
              {sellername}
            </span>
            <p className="flex items-center gap-1 text-xs">
              <span className="text-accents-700 text-[10px]">{content}</span>
            </p>
          </div>
        </div>
        <div>
          <p className="text-accents-700 text-[10px] text-nowrap">
            {/* {formatTime(new Date().toISOString(), cookies.NEXT_LOCALE)} */}
            {timestamp ? formatTime(timestamp as string) : "00:00"}
          </p>
          {/* <span className="h-4 w-4 bg-primary block text-center text-xs text-accents-100 rounded-full font-semibold">
            3
          </span> */}
        </div>
      </div>
    </Link>
  );
};

export default ChatList;
