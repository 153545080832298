"use client";
import { useEffect, useMemo, useRef, useState } from "react";
import io, { Socket } from "socket.io-client";
import { useQueryClient } from "@tanstack/react-query";
import { getToken } from "utils/auth/auth";

// import { useCookies } from "react-cookie";

export const useSocket = () => {
  const queryClient = useQueryClient();
  // const { userData } = useGetuserData();

  const socketRef = useRef<Socket | null>(null);

  let userData: any;
  let cookies: any;
  // const { userData } = useGetProfile();
  // const [cookies] = useCookies(["auth"]);

  // const socket = io(process.env.NEXT_PUBLIC_BASE_API as string, {
  //   path: "/socket",
  //   extraHeaders: {
  //     Authorization: `Bearer ${cookies?.auth?.token}`,
  //   },
  // });

  // const socket = io(process.env.REACT_APP_API_BASE_URL as string, {
  //   path: `/ws/chat/?token=${getToken()}`,
  // });

  const token = getToken();

  console.log(token, "Just token");
  // Memoize socket event names
  const socketEvents = useMemo(() => {
    if (!getToken()) return null;
    return {
      // unreadMessage: `unread_messages_${userData?.data?.userInfo?.id} `,
      // newMessages: `new_message_${userData?.data?.userInfo?.id}`,
      newNotification: `new_notifications_${userData?.data?.userInfo?.id}`,
      unreadNotification: `unread_notifications_${userData?.data?.userInfo?.id}`,
      // unreadMessageFrom: `unread_messages_from_${id && (id as string)}`,
      // newMessageFrom: `new_message_from_${id && (id as string)}`,
    };
  }, [userData?.data?.userInfo?.id]);

  useEffect(() => {
    if (!socketEvents || !cookies?.auth?.token) return;
    socketRef.current = io(process.env.REACT_APP_API_BASE_URL as string, {
      path: `/ws/chat/?token=${getToken()}`,
    });

    // socketRef.current.on(newNotification, (data: any) => {
    //   setIsNew(true);

    // });

    socketRef.current.on(socketEvents.newNotification, (message: string) => {
      if (message) {
        queryClient.invalidateQueries({
          queryKey: ["messages"],
        });
      }
      // queryClient.invalidateQueries({
      //   queryKey: ["messages", userData?.data?.userInfo?.id],
      // });
    });
    socketRef.current.on(socketEvents.unreadNotification, (message: string) => {
      if (message) {
        queryClient.invalidateQueries({
          queryKey: ["messages"],
        });
      }
    });

    return () => {
      socketRef.current?.disconnect();
    };
  }, [socketEvents, cookies?.auth?.token]);

  return { socket: socketRef.current };
};
