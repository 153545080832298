import React, { useRef, useState } from "react";
import { ReactComponent as AddIcon } from "assets/svg/add.svg";
import { ReactComponent as MinusIcon } from "assets/svg/minus.svg";

export const FAQ = () => {
  const [openResponse, setOpenResponse] = useState(Array(4).fill(false));
  const contentRefs = useRef([]);

  const toggleResponse = (index: number) => {
    setOpenResponse((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <div className="container px-6 xlg:px-0 pb-8">
      <div className="flex w-full flex-wrap justify-between">
        <div className="flex flex-col gap-3">
          <div className="flex gap-3 items-center">
            <div className="border-b border-2 border-black w-6" />{" "}
            <span className="text-sm uppercase font-semibold">
              Frequently Asked Questions
            </span>
          </div>
          <span className="md:text-[42px] flex text-[22px] capitalize font-bold leading-[30px] md:leading-[60px]">
            Got questions? <br className="flex lg:hidden" />
            We've <br className="hidden lg:block" /> got answers!
          </span>
        </div>
        <div className="flex flex-col mt-8 lg:mt-0  w-full max-w-[600px]">
          {Array(4)
            .fill(0)
            .map((_, index) => (
              <div key={index}>
                <div className="flex items-center w-full justify-between">
                  <span className="text-[#4188F9] max-w-[500px] font-bold">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </span>
                  <button
                    className="text-bold text-base w-10"
                    onClick={() => toggleResponse(index)}
                  >
                    {openResponse[index] ? (
                      <span className="text-bold text-base w-10">
                        <MinusIcon />
                      </span>
                    ) : (
                      <span className="text-bold text-base w-10">
                        <AddIcon className="w-8" />
                      </span>
                    )}
                  </button>
                </div>

                <div
                  //@ts-expect-error
                  ref={(el) => (contentRefs.current[index] = el)}
                  className={`transition-all duration-300 ease-in-out overflow-hidden max-w-[500px]`}
                  style={{
                    height: openResponse[index]
                      ? //@ts-expect-error
                        `${contentRefs.current[index]?.scrollHeight}px`
                      : "0",
                  }}
                >
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Donec ornare venenatis semper. Quisque facilisis nisi ac
                    viverra lobortis.
                  </span>
                </div>

                {!(openResponse.length === index + 1) && (
                  <div className="w-full border border-[#D4D4D4] my-6" />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
