import {
  Chat,
  RecentJobs,
  ServiceProfileCreation,
  ServiceRequest,
  ProductDetails,
} from "@component";

import { SearchPage, Homepage } from "pages";
import { ProfilePage } from "../pages/SettingsPage/ProfilePage";
import { Notification } from "../pages/SettingsPage/Notification";
import { Settings } from "../pages/SettingsPage/settings";
import { routes } from "routes";
import { ProfileCreationPage } from "pages/ProfileCreation";
import { Favourites } from "pages/favouritesPage";
import { routerType } from "types";
import ChatDetailPage from "component/chat/ChatDetailPage";

// import { Settings } from "lucide-react";

export const pagesData: routerType[] = [
  {
    path: routes.HOME.path,
    element: <Homepage />,
    title: routes.HOME.name,
    useAuth: false,
  },
  {
    path: routes.SEARCH.path,
    element: <SearchPage />,
    title: routes.SEARCH.name,
    useAuth: true,
  },
  {
    path: routes.FAVOURITES.path,
    element: <Favourites />,
    title: routes.FAVOURITES.name,
    useAuth: false,
  },
  {
    path: routes.PROFILE.path,
    element: <ProfilePage />,
    title: routes.PROFILE.name,
    useAuth: true,
  },

  {
    path: routes.NOTIFICATION.path,
    element: <Notification />,
    title: routes.NOTIFICATION.name,
    useAuth: true,
  },

  // {
  //   path: routes.TASKDETAILS.path,
  //   element: <TaskDetails />,
  //   title: routes.TASKDETAILS.name,
  //   useAuth: true,
  // },
  {
    path: routes.RECENTJOBS.path,
    element: <RecentJobs />,
    title: routes.RECENTJOBS.name,
    useAuth: false,
  },
  {
    path: routes.PRODUCTDETAILS.path,
    element: <ProductDetails />,
    title: routes.PRODUCTDETAILS.name,
    useAuth: true,
  },
  {
    path: routes.SETTINGS.path,
    element: <Settings />,
    title: routes.SETTINGS.name,
    useAuth: true,
  },
  // {
  //   path: routes.PROFILECREATION.path,
  //   element: <ProfileCreationPage />,
  //   title: routes.PROFILECREATION?.name,
  //   useAuth: true,
  // // },
  // },

  {
    path: routes.PROVIDER.path,
    element: <ProfileCreationPage />,
    title: routes.PROFILECREATION.path,
    useAuth: false,
  },
  {
    path: routes.SERVICESREQUESTS.path,
    element: <ServiceRequest />,
    title: routes.SERVICESREQUESTS.name,
    useAuth: true,
  },
  {
    path: routes.SERVICEPROFILE.path,
    element: <ServiceProfileCreation />,
    title: routes.SERVICEPROFILE.name,

    useAuth: false,
  },
  {
    path: routes.CHAT.path,
    element: <Chat />,
    title: routes.CHAT.path,
    useAuth: false,
  },
  {
    path: routes.CHATCONTENT.path,
    element: <ChatDetailPage />,
    title: routes.CHAT.path,
    useAuth: false,
  },
  // {
  //   path: routes.SETTINGS.path,
  //   element: <SettingsPage />,
  //   title: routes.SETTINGS.name,
  //   useAuth: true,
  // },
  // {
  //   path: routes.SIGNUP.path,
  //   element: <SignUp />,
  //   title: routes.SIGNUP.name,
  //   useAuth: true,
  // },
  // {
  //   path: routes.LOGIN.path,
  //   element: <LoginCard />,
  //   title: routes.LOGIN.name,
  //   useAuth: true,
  // },
  // {
  //   path: routes.FORGOTPASSWORD.path,
  //   element: <ForgotPassword />,
  //   title: routes.FORGOTPASSWORD.name,
  //   useAuth: true,
  // },
  //add other paths here
];
