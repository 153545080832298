import { SideNavRecentJobs } from "context/jobs/sideNavRecentJobs";
import React from "react";
import { Button } from "ui/button";
import { Card } from "ui/card";
import AvatarImage from "assets/images/avatarImage.png";
import { useNavigate } from "react-router-dom";
import { UsersLayout } from "@component";
import { useDialogStore } from "store";
import { IJobResultData } from "types/job";
import { formatDate, formatTime } from "utils/formatDate";
import useJobBid from "hooks/jobs/useJobBid";

export const RecentJobs = () => {
  const navigate = useNavigate();
  const selected = useDialogStore((state) => state.selected);

  const { bidJobMutation, isBiddingJob } = useJobBid();
  //@Todo placehilder
  const selectedJob: any = selected?.data;

  const handleJobBid = async () => {
    await bidJobMutation({
      id: selectedJob?.id,
      amount: selectedJob?.budget,
      comment: "I would like to bid for this job",
    });
  };

  return (
    <div className="mt-[80px]">
      <UsersLayout sideChildren={<SideNavRecentJobs />}>
        <div className="p-4 flex flex-col gap-4">
          <span className="font-bold text-[24px]">
            I am Looking for a {selectedJob?.title}
          </span>
          <div className="flex items-center gap-1">
            <span className="font-bold text-[26px]">
              ${selectedJob?.budget}
            </span>
            <span className="text-sm">Budget</span>
          </div>
          <div className=" w-full flex flex-col gap-4">
            <div className="grid w-[90%] gap-5 grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
              <Card className=" w-full">
                <img
                  src={selectedJob?.images && selectedJob?.images[0]?.image_url}
                  alt="service img"
                  className="w-full h-full bg-cover "
                />
              </Card>
            </div>
            <div className="mt-6">
              <span className="text-sm font-medium">Description</span>
              {/* <span>{jobsData?.results[0]?.description}</span> */}
              <div className="bg-[#F1F5F9] flex-col justify-center flex gap-4 mt-g rounded-[10px] mt-6 max-w-[444px] w-full p-3">
                {[
                  {
                    label: "Time",
                    value:
                      selectedJob?.service_timeframe &&
                      `${formatTime(selectedJob?.updated_at)}`,
                  },
                  { label: "Status", value: selectedJob?.status },
                  {
                    label: "Deadline:",
                    value: formatDate(selectedJob?.deadline),
                  },
                  { label: "Location:", value: selectedJob?.location },
                ]?.map((v) => (
                  <div className="flex items-center gap-6" key={v?.label}>
                    <div className="flex flex-col gap-3 w-full max-w-[60px]">
                      <span className="text-xs font-semibold text-[#71717A]">
                        {v?.label}
                      </span>
                    </div>
                    <div className="flex flex-col gap-3">
                      {v?.value === "Open" ? (
                        <div className="w-[94px] h-[34px] rounded-[16px] bg-[#4188F9] flex items-center justify-center">
                          <span className="text-white text-sm  font-medium">
                            {v?.value}
                          </span>
                        </div>
                      ) : (
                        <span className="text-xs font-semibold">
                          {v?.value}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col justify-center gap-4 mt-6">
                <div className=" flex items-center gap-3">
                  <div className="w-fit px-6 h-[34px] rounded-[16px] bg-[#F6F6F6] flex items-center justify-center">
                    <span className="text-black text-sm  font-medium">
                      Share
                    </span>
                  </div>{" "}
                  <div className="w-fit px-6  h-[34px] rounded-[16px] bg-[#F6F6F6] flex items-center justify-center">
                    <span className="text-black text-sm  font-medium">
                      Repot this task
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-3 mt-6">
                  <span className="font-bold">Comments & Offers</span>
                  <div
                    className="w-fit px-6  h-[34px] rounded-[16px] bg-[#DBEAFE] flex items-center justify-center"
                    onClick={handleJobBid}
                  >
                    <span className="text-[#2563EB] text-sm  font-medium">
                      Add a offer
                    </span>
                  </div>
                  <div className=" my-4 w-full">
                    {selectedJob?.bids &&
                      selectedJob?.bids.length > 0 &&
                      selectedJob?.bids.map(
                        (bidItem: {
                          bidder: {
                            profile_picture: string | undefined;
                            fullname:
                              | string
                              | number
                              | boolean
                              | React.ReactElement<
                                  any,
                                  string | React.JSXElementConstructor<any>
                                >
                              | Iterable<React.ReactNode>
                              | React.ReactPortal
                              | null
                              | undefined;
                          };
                          amount:
                            | string
                            | number
                            | boolean
                            | React.ReactElement<
                                any,
                                string | React.JSXElementConstructor<any>
                              >
                            | Iterable<React.ReactNode>
                            | React.ReactPortal
                            | null
                            | undefined;
                          comment:
                            | string
                            | number
                            | boolean
                            | React.ReactElement<
                                any,
                                string | React.JSXElementConstructor<any>
                              >
                            | Iterable<React.ReactNode>
                            | React.ReactPortal
                            | null
                            | undefined;
                        }) => (
                          <>
                            <div className="w-full flex  justify-between">
                              <div className="flex  gap-3 items-center">
                                <div className="w-[clamp(100px_8vw_100px)] h-fit rounded-[10px] ">
                                  <img
                                    src={bidItem?.bidder?.profile_picture}
                                    alt="avatar img"
                                    className="w-[60px] h-[60px] rounded-[10px]"
                                  />
                                </div>
                                <div className="flex flex-col gap-1">
                                  <span className="font-bold">
                                    {bidItem?.bidder?.fullname} - Made an offer:
                                    ${bidItem?.amount} -{" "}
                                    <span className="text-xs text-[#7A7B7F]">
                                      1 day ago
                                    </span>
                                  </span>
                                  <span className="text-sm text-[#54565A]">
                                    {bidItem?.comment}
                                  </span>
                                </div>
                              </div>
                              <div onClick={() => navigate("/chat")}>
                                <Button size="sm">Message</Button>
                              </div>
                            </div>
                            <div className="w-full my-5 relative inset-0 border outline-none  shadow-sm px-[-60px] hidden lg:block" />
                          </>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </UsersLayout>
    </div>
  );
};
