import { SettingsLayout } from "context/settings/layout/SettingsLayout";
import { ToggleSwitch } from "flowbite-react";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "ui/button";
import SwitchContainer from "ui/switch";

export const Settings = () => {
  const methods = useForm<any>({});
  return (
    <SettingsLayout>
      <FormProvider {...methods}>
        <form className="py-3 px-6 flex w-full  flex-col">
          <span className="font-bold mb-6">Settings</span>
          <span className="text-sm font-semibold text-[#64748B]">
            SIGN-IN METHOD
          </span>
          <div className="flex flex-col my-4 gap-6">
            <div className="w-full justify-between flex">
              <div className="flex flex-col">
                <span className="font-bold">Email Address</span>
                <span className="text-xs">usmanzafar@gmail.com</span>
              </div>
              <Button variant="light-blue" size="sm">
                Change Email
              </Button>
            </div>
            <div className="w-full justify-between flex">
              <div className="flex flex-col">
                <span className="font-bold">Password</span>
                <span className="text-xs">********</span>
              </div>
              <Button variant="light-blue" size="sm">
                Reset Password
              </Button>
            </div>
          </div>
          <div className=" border border-[#E2E8F0] px-[-30px] my-4 mx-[-25px]" />

          <div className="flex flex-col">
            <div className="flex flex-col">
              <div className="mb-3">
                <span className="text-sm font-semibold  text-[#64748B]">
                  TWO-STEP VERIFICATION
                </span>
              </div>

              <div className="flex flex-col gap-6">
                <div className="w-full flex justify-between">
                  <div className="flex flex-col w-full " key={Math.random()}>
                    <span className=" font-bold ">Authenticator app</span>
                    <span className="text-sm  text-[#64748B]">
                      Enter a code generated by your authenticator app to
                      confirm it&apos;s you.
                    </span>
                  </div>
                  <div>
                    <SwitchContainer
                      name={"switch"}
                      handleToggle={() => {
                        console.log("too");
                      }}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <div className="w-full flex justify-between">
                    <div className="flex flex-col w-full " key={Math.random()}>
                      <span className=" font-bold ">Mobile app prompt</span>
                      <span className="text-sm  text-[#64748B]">
                        Receive a prompt from your mobile app to confirm
                        it&apos;s you.
                      </span>
                    </div>
                    <div>
                      <SwitchContainer
                        name={"switch"}
                        handleToggle={() => {
                          console.log("toggled");
                        }}
                      />
                    </div>
                  </div>
                  <div className=" border border-[#E2E8F0] px-[-30px] my-6 mx-[-25px]" />
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="mb-3">
                <span className="text-sm font-semibold  text-[#64748B]">
                  PREFERENCES
                </span>
              </div>

              <div className="flex flex-col gap-6">
                <div className="w-full flex justify-between">
                  <div className="flex flex-col w-full " key={Math.random()}>
                    <span className=" font-bold ">Successful Payments</span>
                    <span className="text-sm  text-[#64748B]">
                      Receive a notification for every successful payment.
                    </span>
                  </div>
                  <div>
                    <SwitchContainer
                      name={"switch"}
                      handleToggle={() => {
                        console.log("first");
                      }}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <div className="w-full flex justify-between">
                    <div className="flex flex-col w-full " key={Math.random()}>
                      <span className=" font-bold ">Invoice Payments</span>
                      <span className="text-sm  text-[#64748B]">
                        Receive a notification it a customer sends an incorrect
                        amount to pay their Invoice
                      </span>
                    </div>
                    <div>
                      <SwitchContainer
                        name={"switch"}
                        handleToggle={() => {
                          console.log("first");
                        }}
                      />
                    </div>
                  </div>
                  <div className=" border border-[#E2E8F0] px-[-30px] my-6 mx-[-25px]" />
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="mb-3">
                <span className="text-sm font-semibold  text-[#64748B]">
                  APPERENCES
                </span>
              </div>

              <div className="flex flex-col gap-6">
                <div className="w-full flex justify-between">
                  <div className="flex flex-col w-full " key={Math.random()}>
                    <span className=" font-bold ">Theme</span>
                    <span className="text-sm  text-[#64748B]">
                      Change the appearance of your dashboard
                    </span>
                  </div>
                  <div>
                    <SwitchContainer
                      name={"switch"}
                      handleToggle={() => {
                        console.log("first");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </SettingsLayout>
  );
};
