// import {useCreateServiceProviderProfile} from "hooks";
// import React, { useCallback, useState } from "react";
// import { useDropzone } from "react-dropzone";
// import { FormProvider, useForm } from "react-hook-form";
// import { Button } from "ui/button";
// import { useFormStore } from "store";
// import { useNavigate } from "react-router-dom";

// const UploadMedia = ({ onSubmit }: { onSubmit: (term: string) => void }) => {
//   const [files, setFiles] = useState<any[]>([]);
//   const [filesRejected, setFilesRejected] = useState<any[]>([]);
//   const { createServiceProviderMutation } = useCreateServiceProviderProfile();
//   const navigate = useNavigate();

//   const selected = useFormStore((state) => state.selected);
//   const formPayload: IFormPayload = selected.data;

// const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {
//   // Do something with the files
//   console.log(acceptedFiles);
//   console.log(rejectedFiles);
//   if (acceptedFiles?.length) {
//     setFiles((prev) => [
//       ...prev,
//       ...acceptedFiles.map(
//         (file: any) =>
//           Object.assign(file, { preview: URL.createObjectURL(file) })
//         // Object.assign(URL.createObjectURL(file))
//       ),
//     ]);
//   }

//   if (rejectedFiles?.length) {
//     setFilesRejected((prev) => [...prev, ...rejectedFiles]);
//   }
// }, []);
//   // console.log(files);
//   const methods = useForm();
// const { getRootProps, getInputProps, isDragActive } = useDropzone({
//   onDrop,
//   accept: {
//     "image/*": [],
//   },
//   maxSize: 1024 * 1000,
// });

// const removeFile = (name: string) => {
//   setFiles((files) => files.filter((file) => file.name !== name));
// };
// const removeRejectedFile = (name: string) => {
//   setFilesRejected((files) => files.filter((file) => file.name !== name));
// };

//   const onFormSubmit = async () => {
//     const formData = new FormData();

//     formData.append("profile_picture", formPayload?.pictures[0]);
//     formPayload?.skills.forEach((item, index) => {
//       formData.append(`skill_data[${index}]skill`, item.skills);
//       formData.append(`skill_data[${index}]cost`, `${item.cost}`);
//       formData.append(`skill_data[${index}]time_frame`, `${item.time_frame}`);
//     });
//     formData.append("skill_data", JSON.stringify(formPayload.skills));
//     formData.append("images", files[0]);
//     formData.append("business_name", formPayload.name);
//     formData.append("country", formPayload.country);
//     formData.append("state", formPayload.state);
//     formData.append("city", formPayload.state);
//     formData.append("phone", formPayload?.phoneNumber);
//     formData.append("zip_code", formPayload?.zipCode);
//     formData.append("about_me", formPayload?.zipCode);
//     formData.append("is_active", "true");

//     navigate("/service-requests");
//     const res = await createServiceProviderMutation(formData);

//     console.log(res);
//   };

//   return (
//     <section>
//       <FormProvider {...methods}>
//         <div
//           {...getRootProps()}
//           className="p-16 mt-10 border border-neutral-200"
//         >
//           <input {...getInputProps()} />
//           {isDragActive ? (
//             <p>Drop the files here ...</p>
//           ) : (
//             <p>Drag 'n' drop some files here, or click to select files</p>
//           )}
//         </div>
//         <div className="flex  fixed bottom-0 left-0 right-0 gap-4 h-[80px]  bg-white justify-end border-t border-gray-200">
//           <div className="flex items-center w-full justify-end gap-4 container">
//             <Button
//               variant="light-blue"
//               className="w-fit px-10"
//               onClick={() => onSubmit("skills")}
//             >
//               Cancel
//             </Button>
//             <Button
//               variant="default"
//               size="sm"
//               className="w-fit px-14"
//               onClick={onFormSubmit}
//             >
//               Submit
//             </Button>
//           </div>
//         </div>
//       </FormProvider>
//       <ul className="flex gap-4">
//         {files?.map((file) => (
//           <li key={file.name}>
//             <img
//               src={file.preview}
//               alt={file.name}
//               height={100}
//               width={100}
//               onLoad={() => URL.revokeObjectURL(file.preview)}
//             />
//           </li>
//         ))}
//       </ul>

//       <ul>
//         {filesRejected.map(({ file, errors }) => (
//           <li key={file?.name}>
//             <div>
//               <p>{file.name}</p>
//               <ul>
//                 {errors?.map((err: any) => (
//                   <li key={err?.code}>{err?.message}</li>
//                 ))}
//               </ul>
//             </div>
//           </li>
//         ))}
//       </ul>
//     </section>
//   );
// };

// interface IFormPayload {
//   name: string;
//   email: string;
//   country: string;
//   state: string;
//   phoneNumber: string;
//   dob: string;
//   zipCode: string;
//   pictures: File[];
//   skills: {
//     cost: number;
//     time_frame: string;
//     skills: string;
//   }[];
// }
// export default UploadMedia;

import React, { useState, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "ui/button";
import { useDropzone } from "react-dropzone";
import { useFormStore } from "store";
import { useCreateServiceProviderProfile } from "hooks";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { ICreateEmailType } from "types";
import { createAnEmail } from "services";
import { notify } from "shared";
import { ConfirmOtp } from "./ConfirmOtp";

export const UploadMedia = ({
  onSubmit,
  onBack,
}: {
  onSubmit: () => void;
  onBack: () => void;
}) => {
  const [files, setFiles] = useState<any[]>([]);
  const [filesRejected, setFilesRejected] = useState<any[]>([]);
  const { createServiceProviderMutation } = useCreateServiceProviderProfile();
  const [otpModal, setOtpModal] = useState("");
  const navigate = useNavigate();
  const [secretKey, setSecretKey] = useState("");

  const { mutateAsync: emailMutate, isPending: isLoading } = useMutation({
    mutationFn: async (payload: ICreateEmailType) =>
      await createAnEmail({ ...payload }),
    onSuccess: async (response) => {
      // setOpenSignUp(false);
      setSecretKey(response?.data);
      // setOpenConfirmPassword(true);
      notify.success({
        message: "Success",
        subtitle: "sent successfully",
      });
    },
    onError: async ({ response }: any) => {
      notify.error({
        message: "Error",
        subtitle: response?.data?.error || "",
      });
    },
  });

  const selected = useFormStore((state) => state.selected);

  const formPayload = selected.data;
  const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {
    // Do something with the files
    if (acceptedFiles?.length) {
      setFiles((prev) => [
        ...prev,
        ...acceptedFiles.map(
          (file: any) =>
            Object.assign(file, { preview: URL.createObjectURL(file) })
          // Object.assign(URL.createObjectURL(file))
        ),
      ]);
    }

    if (rejectedFiles?.length) {
      setFilesRejected((prev) => [...prev, ...rejectedFiles]);
    }
  }, []);
  // console.log(files);
  const methods = useForm();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
    maxSize: 1024 * 1000,
  });

  const removeFile = (name: string) => {
    setFiles((files) => files.filter((file) => file.name !== name));
  };
  const removeRejectedFile = (name: string) => {
    setFilesRejected((files) => files.filter((file) => file.name !== name));
  };

  // console.log(files);

  const onFormSubmit = async () => {
    const formData = new FormData();

    formData.append("profile_picture", formPayload?.pictures[0]);
    formPayload?.skills.forEach((item: any, index: any) => {
      formData.append(`skill_data[${index}]skill`, item.skills);
      formData.append(`skill_data[${index}]cost`, `${item.cost}`);
      formData.append(`skill_data[${index}]time_frame`, `${item.time_frame}`);
    });
    formData.append("skill_data", JSON.stringify(formPayload.skills));
    formData.append("images", files[0]);
    formData.append("business_name", formPayload.name);
    formData.append("country", formPayload.country);
    formData.append("state", formPayload.state);
    formData.append("city", formPayload.state);
    formData.append("phone", formPayload?.phoneNumber);
    formData.append("zip_code", formPayload?.zipCode);
    formData.append("about_me", formPayload?.zipCode);
    formData.append("is_active", "true");

    // setOtpModal("confirmOtp");

    // navigate("/service-requests");
    // const res = await createServiceProviderMutation(formData);
  };

  const validateEmail = async () => {
    const res = await emailMutate({
      email: formPayload?.email,
    });

    if (res?.success) {
      setOtpModal("confirmOtp");
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <section>
          <div
            {...getRootProps()}
            className="p-16 mt-10 border border-neutral-200"
          >
            <input {...getInputProps()} />
            <p className="font-medium text-2xl text-center">
              Drag n drop some files here, or click to select files
            </p>
          </div>

          <div className="grid grid-cols-4 gap-4 mt-4">
            {files.map((item) => (
              <div key={item.name}>
                <img src={item?.preview} alt="" className="w-full h-[200px]" />
              </div>
            ))}
          </div>

          <div className="flex fixed bottom-0 left-0 right-0 gap-4 h-[80px] bg-white justify-end border-t border-gray-200">
            <div className="flex items-center w-full justify-end gap-4 container">
              <Button
                variant="light-blue"
                className="w-fit px-10"
                onClick={onBack}
              >
                Back
              </Button>
              <Button
                variant="default"
                size="sm"
                className="w-fit px-14"
                onClick={validateEmail}
              >
                Submit
              </Button>
            </div>
          </div>
        </section>
      </FormProvider>
      <ConfirmOtp
        // handleSignUp={() => {}}
        isLoading={false}
        openOtp={otpModal}
        setOtpModal={setOtpModal}
        files={files}
        secretKey={secretKey}
        onClose={setOtpModal}
        setSecretKey={setSecretKey}
      />
    </>
  );
};
