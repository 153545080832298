import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "ui/button";
import CustomSelect from "ui/controlledSelectedComponent";
import FileUploader from "ui/fileUploader";
import { FormTextInput } from "ui/formTextInput";
import { profileSchema } from "./profileSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFormStore } from "store";

export const PersonalInfoForm = ({ onSubmit }: { onSubmit: () => void }) => {
  const methods = useForm({
    mode: "onChange", // Enables real-time validation
    resolver: yupResolver(profileSchema), // Custom resolver
  });

  const setSelected = useFormStore((state) => state.setSelected);

  const {
    handleSubmit,
    formState: { errors, isValid }, // Access form validation state
    register,
  } = methods;

  const onFormSubmit = (data: any) => {
    setSelected({
      ...data,
    });
    // If form is valid, proceed to the next step
    onSubmit();
  };

  return (
    <FormProvider {...methods}>
      <form
        className="grid grid-cols-1 px-4  md:grid-cols-[1fr_1fr_auto] gap-6 pb-10"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        {/* Personal Information Fields */}
        <div className="flex flex-col gap-8">
          <FormTextInput
            //name={"name"}
            labelText="Business Name"
            placeholder="Enter your business name..."
            {...register("name")}
          />

          <FormTextInput
            //name={"email"}
            labelText="Email Address"
            placeholder="Enter your email address..."
            {...register("email")}
          />
          <FormTextInput
            //name={"email"}
            labelText="Password"
            placeholder="Enter your password..."
            {...register("password")}
          />

          <FormTextInput
            // name={"country"}
            labelText="Country"
            placeholder="Enter your country..."
            {...register("country")}
          />

          <FormTextInput
            //  name={"state"}
            labelText="State"
            placeholder="Enter your state..."
            {...register("state")}
          />
        </div>

        {/* Contact Information Fields */}
        <div className="flex flex-col gap-8">
          <FormTextInput
            //  name={"phoneNumber"}
            labelText="Phone Number"
            placeholder="Enter your phone number..."
            {...register("phoneNumber")}
          />

          {/* <CustomSelect
            // name={"city"}
            label="City"
            placeholder="Select your city..."
            options={[]} // Add your city options here
            {...register("city", { required: "City is required" })}
          /> */}

          <FormTextInput
            //  name={"state"}
            labelText="City"
            placeholder="Enter your state..."
            {...register("city")}
          />

          <FormTextInput
            // name={"zipCode"}
            labelText="Zip Code"
            placeholder="Enter your zip code..."
            {...register("zipCode")}
          />
        </div>

        {/* File Upload */}
        <div className="min-w-[18rem] h-full pb-3">
          <FileUploader
            id={`file-input-cac`}
            snapType={""}
            isMultiple={true}
            variant="type2"
            {...register("pictures")} // Optional, depending on how FileUploader handles data
          />
        </div>

        {/* Button Control */}
        <div className="flex fixed bottom-0 left-0 right-0 gap-4 h-[80px] bg-white justify-end border-t border-gray-200">
          <div className="flex items-center w-full justify-end gap-4 container">
            <Button variant="light-blue" className="w-fit px-10">
              Cancel
            </Button>
            <Button
              variant="default"
              size="sm"
              className="w-fit px-14"
              type="submit"
              disabled={!isValid} // Disable until form is valid
            >
              Next
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
