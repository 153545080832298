interface Route {
  name: string;
  path: string;
}

export const routes: Record<string, Route> = {
  HOME: {
    name: "home",
    path: "/",
  },

  SEARCH: {
    name: "search",
    path: "/search",
  },

  PROFILE: {
    name: "profile",
    path: "/profile",
  },
  PROVIDER: {
    name: "provider",
    path: "/provider",
  },

  SETTINGS: {
    name: "settings",
    path: "/settings",
  },

  NOTIFICATION: {
    name: "notification",
    path: "/notification",
  },

  TASKDETAILS: {
    name: "task-details",
    path: "/task-details",
  },
  RECENTJOBS: {
    name: "recent-jobs",
    path: "/recent-jobs",
  },
  PRODUCTDETAILS: {
    name: "product-details",
    path: "/product-details/:id",
  },
  PROFILECREATION: {
    name: "profile-creation",
    path: "/profile-creation",
  },
  SERVICESREQUESTS: {
    name: "service-requests",
    path: "/service-requests",
  },
  SERVICEPROFILE: {
    name: "service-profile-creation",
    path: "/service-profile-creation",
  },
  FAVOURITES: {
    name: "favourites",
    path: "/favourite",
  },
  CHAT: {
    name: "chat",
    path: "/chat",
  },
  CHATCONTENT: {
    name: "chat",
    path: "/chat/:id",
  },
  // LOGIN: {
  //   name: "login",
  //   path: "/login",
  // },
  // SIGNUP: {
  //   name: "sign-up",
  //   path: "/sign-up",
  // },
  // FORGOTPASSWORD: {
  //   name: "forgot-password",
  //   path: "/forgot-password",
  // },
  // Add other routes here
};
