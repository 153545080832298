import React, { useState } from "react";
import CustomAvatar from "./CustomAvatar";
import ChatList from "./ChatList";
import { useGetConvoList } from "hooks/services/useGetConvoList";
import { useAuthenticatedUser } from "hooks";

const DesktopSIdebar = () => {
  const { allChatList } = useGetConvoList();
  const [term, setTerm] = useState("");
  const { userDetails } = useAuthenticatedUser();

  const chattersList = allChatList?.results?.map((item) => ({
    fullname: item?.recipient?.fullname,
    profilePic: item?.recipient?.profile_picture,
    id: item?.id,
    content: item?.last_message_content,
    timestamp: item?.last_message_time,
  }));

  // const filteredContact = chattersList?.filter((item) =>
  //   item?.fullname?.toLowerCase().includes(term)
  // );

  return (
    <div className="bg-accents-100 rounded-lg mt-[16px]">
      <div className="flex items-center gap-2 p-3 border-b-accents-300 border ">
        <CustomAvatar
          username={userDetails?.data?.fullname}
          src={userDetails?.data?.profile_picture}
        />

        <div className="flex flex-col">
          <span className="text-xs">{userDetails?.data?.fullname}</span>
          <p className="flex items-center gap-1 text-xs">
            <span className="text-accents-700">{"online"}</span>
            <span>{/* <GreenDot /> */}</span>
          </p>
        </div>
      </div>
      <div className="bg-accents-100">
        <div
          className={`flex  w-full items-center rounded-[9px] border border-dark-light px-[16px]  relative  bg-accents-100`}
        >
          {/* <SearchIcon className=" right-4 bg-transparent text-white bg-white" /> */}

          <input
            type="text"
            onChange={(e) => setTerm(e.target.value)}
            value={term}
            className={`w-full borde border-accents-500 bg-accents-100 px-3 py-2 text-md  rounded-lg file:text-sm file:font-medium placeholder:text-accents-500 placeholder:text-[10px] md:placeholder:text-sm focus-visible:outline-none focus-visible:ring-primary focus-visible:ring-0 dark:focus-visible:ring-primary dark:placeholder:text-accents-500 disable:opacity-50 disabled:cursor-not-allowed h-10 flex file:border-0`}
          />
        </div>
      </div>
      <div className="h-[450px] overflow-auto scrollbar-hide">
        {chattersList?.map((item) => (
          <ChatList
            key={item.id}
            sellername={item.fullname}
            content={item?.content}
            timestamp={item?.timestamp}
            id={item?.id}
            // imgUrl={item?.imgUrl as string}
          />
        ))}
      </div>
    </div>
  );
};

export default DesktopSIdebar;
