import { IconProps } from "assets/Icons/IconProps";

export default function SendIcon({ color = "#35a908", ...props }: IconProps) {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle cx="16" cy="16" r="16" fill={color} />
        <g clipPath="url(#clip0_1_1458)">
          <path
            d="M14.9997 17L21.9997 10M15.0847 17.2187L16.8368 21.7241C16.9911 22.121 17.0683 22.3194 17.1795 22.3774C17.2759 22.4276 17.3908 22.4276 17.4872 22.3775C17.5985 22.3197 17.6759 22.1214 17.8307 21.7247L22.2243 10.4662C22.364 10.108 22.4339 9.92898 22.3957 9.81456C22.3625 9.71519 22.2845 9.63721 22.1851 9.60402C22.0707 9.56579 21.8917 9.63567 21.5335 9.77542L10.275 14.169C9.87833 14.3238 9.67997 14.4012 9.62216 14.5125C9.57205 14.6089 9.57212 14.7238 9.62234 14.8202C9.68028 14.9314 9.87873 15.0085 10.2756 15.1629L14.781 16.915C14.8615 16.9463 14.9018 16.962 14.9357 16.9862C14.9658 17.0076 14.9921 17.0339 15.0135 17.064C15.0377 17.0979 15.0534 17.1382 15.0847 17.2187Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_1458">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(8 8)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
