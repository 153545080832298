import { IconX } from "@tabler/icons-react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

// import tv2SansDisplay from "@/styles/fonts";

// function NotifCloseIcon() {
//   return (
//     <svg
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M7.5 7.5L12 12M12 12L7.5 16.5M12 12L16.5 16.5M12 12L16.5 7.5"
//         stroke="#999CA0"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </svg>
//   );
// }

export function NotificationContainer() {
  return (
    // <div>kk</div>
    <ToastContainer
      // bodyStyle={"font-tv2Display"}
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      icon={<IconX color="gray" style={{ width: "1rem", height: "1rem" }} />}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={
        <IconX color="gray" style={{ width: "1rem", height: "1rem" }} />
      }
      theme="light"
      limit={1}
    />
  );
}
