import Api from "utils/auth/api";
import { IUserProfile } from "types/auth";
import {
  IAllChatList,
  IConversation,
  IFavoriteResponseData,
  IFavoriteServiceData,
  InitiateConvo,
  IServiceProviderAccount,
} from "types/favorite";
import { IQueryParams, queryParamsHelper } from "./query-params";

export const getUserProfile = async () => {
  return Api.get<IUserProfile>("/user/profile/").then((res) => res.data);
};

export const createServiceProviderProfile = async (payload: any) => {
  return Api.post<IServiceProviderAccount>("/service/", payload).then(
    (res) => res.data
  );
};

export const getServiceProviderProfile = async ({ queryKey }: IQueryParams) => {
  const queryParams = queryParamsHelper(queryKey?.[1]);

  return Api.get<IFavoriteResponseData>(`/service/${queryParams}`).then(
    (res) => res.data
  );
};
export const getConversation = async (payload: any) => {
  return Api.post<InitiateConvo>(`/conversation/`, payload).then(
    (res) => res.data
  );
};
export const getConversationList = async () => {
  return Api.get<IAllChatList>(`/conversation/`).then((res) => res.data);
};
export const makeConversation = async (payload: any) => {
  return Api.post<any>(
    `/conversation/${payload.id}/send-message/`,
    payload
  ).then((res) => res.data);
};
export const getPreviousConversation = async (payload: any) => {
  return Api.get<IConversation>(`/conversation/${payload}/messages/`).then(
    (res) => res.data
  );
};
export const getServiceProviderProfileById = async (id: string) => {
  return Api.get<IFavoriteServiceData>(`/service/${id}/`).then(
    (res) => res.data
  );
};

export const getService = async (payload: string) => {
  return Api.get(`/skill/?search=${payload}`).then((res) => res.data);
};
export const getSkill = async (payload: string) => {
  return Api.get(`/category/?search=${payload}`).then((res) => res.data);
};

// Delete the user profile
export const deleteUserProfile = async () => {
  return Api.delete("/user/delete-profile/").then((res) => res.data);
};

// Update the user profile with a FormData payload (for profile picture, etc.)
export const updateUserProfile = async (formData: FormData) => {
  return Api.put("/user/update-profile/", formData).then((res) => res.data);
};
