import React, { useState, useRef, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Avatar } from "ui/avatar";
import { Button } from "ui/button";
import CustomSelect from "ui/controlledSelectedComponent";
import FormTextarea from "ui/formTextArea";
import { FormTextInput } from "ui/formTextInput";
import { ReactComponent as LinkIcon } from "assets/svg/link-2.svg";
import { profileSchema } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { IGetProfileType } from "../type";
import DisplayInputWrapper from "ui/displayInputWrapper";

import { notify } from "shared";
import ConfirmDialog from "ui/confirmDialog";
import { updateUserProfile, deleteUserProfile } from "services";

export const Profile = () => {
  const { userDetails } = useAuthenticatedUser();
  const queryClient = useQueryClient();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(
    userDetails?.data?.profile_picture ?? null,
  );
  const [imageFile, setImageFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const profileData = [
    {
      label: "Male",
      value: "MALE",
    },
    {
      label: "Female",
      value: "FEMALE",
    },
  ];

  const methods = useForm<IGetProfileType>({
    mode: "onChange",
    defaultValues: {
      name: userDetails?.data?.fullname,
      aboutMe: userDetails?.data?.about_me,
      phone: userDetails?.data?.phone,
      gender: userDetails?.data?.gender,
      image: userDetails?.data?.profile_picture,
      countryCode: "",
      dob: userDetails?.data?.dob,
    },
    // resolver: yupResolver(profileSchema),
  });

  const { mutate: updateMutate, isPending: isFetching } = useMutation({
    mutationFn: updateUserProfile,
    onSuccess: async (data) => {
      if (data?.status === "success") {
        queryClient.invalidateQueries({
          queryKey: ["user-profile"],
        });
        notify.success({ message: "user updated successfully" });
      }
    },
    onError: async (err) =>
      notify.error({
        message: "Error while updating user information",
        subtitle: err?.message,
      }),
  });

  const onSubmit = async (payload: IGetProfileType) => {
    const formData = new FormData();
    formData.append("about_me", payload.aboutMe!);
    formData.append("fullname", payload.name!);
    formData.append("gender", payload.gender!);
    formData.append("dob", payload.dob!);
    formData.append("phone", payload.phone!);
    if (selectedImage) {
      const blob = await fetchBlobFromUrl(selectedImage);
      formData.append("profile_picture", blob);
    }
    formData.append("country_code", "234");

    updateMutate(formData);
  };

  const fetchBlobFromUrl = async (url: string): Promise<Blob> => {
    const response = await fetch(url);
    return await response.blob();
  };

  const { mutate, isPending } = useMutation({
    mutationFn: async () => await deleteUserProfile(),
    onSuccess: async (data) => {
      setShowDeleteModal(false);
      queryClient.invalidateQueries({
        queryKey: ["user-profile"],
      });
      notify.success({ message: "user deleted successfully" });
    },
    onError: async (err) => {
      setShowDeleteModal(false);
      notify.error({
        message: "Error while deleting user information",
        subtitle: err?.message,
      });
    },
  });

  const handleDeleteProfile = () => {
    mutate();
    window.location.reload();
  };

  // const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file && file.size <= 5 * 1024 * 1024) {
  //     // Check file size (5MB max)
  //     const objectUrl = URL.createObjectURL(file);
  //     setSelectedImage(objectUrl);
  //     setImageFile(file);
  //     methods.setValue("image", objectUrl);
  //   } else {
  //     notify.error({ message: "File is too large. Max size is 5MB." });
  //   }
  // };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validImageTypes.includes(file.type)) {
        notify.error({
          message:
            "Only image files with extensions jpg, jpeg, png are allowed.",
        });
        return;
      }
      if (file.size <= 5 * 1024 * 1024) {
        // Check file size (5MB max)
        const objectUrl = URL.createObjectURL(file);
        setImageFile(file); // Store file instead of URL
        setSelectedImage(objectUrl);
        methods.setValue("image", objectUrl);
      } else {
        notify.error({ message: "File is too large. Max size is 5MB." });
      }
    }
  };

  useEffect(() => {
    methods.setValue("aboutMe", userDetails?.data?.about_me);
  }, [userDetails?.data]);

  const handleChangePictureClick = () => {
    fileInputRef.current?.click();
  };
  return (
    <div className="py-4 px-6 h-full flex flex-col gap-4 w-full">
      <span className="text-sm font-bold">My Profile</span>
      <span className="text-[#64748B] text-sm font-bold">MY PROFILE</span>
      <div className="max-w-[495px] w-full h-full">
        <div className="flex flex-col lg:flex-row lg:flex mb-20 lg:mb-0 gap-3 w-full h-28 items-center">
          <div className="h-full flex items-end">
            <Avatar
              size={100}
              className="flex items-end text-black font-bold"
              fallbackText={userDetails?.data?.fullname}
              src={selectedImage ?? userDetails?.data?.profile_picture}
            />
          </div>
          <div className="flex items-end h-full gap-2">
            <Button
              size="sm"
              variant={"default"}
              onClick={handleChangePictureClick}
            >
              Change Picture
            </Button>
            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={fileInputRef}
              onChange={handleImageChange}
            />
            <Button
              variant="destructive"
              size="sm"
              onClick={() => setSelectedImage(null)}
            >
              Delete
            </Button>
          </div>
        </div>
        <div>
          <FormProvider {...methods}>
            <form
              className="flex flex-col gap-6 mt-6"
              onSubmit={methods.handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              <DisplayInputWrapper
                name="name"
                labelText="Name"
                placeholder="Name"
                type="text"
                setValue={(str) => methods.setValue("name", str)}
                defaultText={userDetails?.data?.fullname}
                labelClass="font-bold"
              />

              <DisplayInputWrapper
                name="dob"
                labelText="Date of birth"
                placeholder="date"
                type="date"
                setValue={(str) => methods.setValue("dob", str)}
                defaultText={userDetails?.data?.dob}
                labelClass="font-bold"
              />

              <div className="z-10 w-[93%]">
                <DisplayInputWrapper
                  name="gender"
                  labelText="Gender"
                  placeholder="Gender"
                  type="gender"
                  setValue={(str) => methods.setValue("gender", str)}
                  defaultText={userDetails?.data?.gender}
                  labelClass="font-bold "
                  options={profileData}
                />
              </div>

              {/* <CustomSelect
                options={profileData}
                name="gender"
                label="Gender"
                placeholder="Select your gender"
                labelClass="font-bold"

              /> */}
              <DisplayInputWrapper
                name="phone"
                labelText="Phone"
                placeholder="phone"
                type="phone"
                setValue={(str) => methods.setValue("phone", str)}
                defaultText={userDetails?.data?.phone}
                labelClass="font-bold"
              />
              <FormTextarea
                name="aboutMe"
                label="About Me"
                placeholder="Type here"
                labelClass="font-bold text-sm "
                rows={6}
              />
              <Button
                type="submit"
                disabled={isFetching}
                className="w-full"
                loading={isFetching}
                itemType="image/jpeg"
              >
                Update Information
              </Button>
            </form>
          </FormProvider>
        </div>
      </div>
      <div className="border border-[#E2E8F0] mx-[-25px] mt-4" />

      <div className="flex flex-col gap-4 w-full pb-6">
        <span className="text-sm mt-2 font-bold text-[#64748B]">
          OPTIONAL INFORMATION
        </span>
        <div className="w-full flex items-center justify-between">
          <div className="flex flex-col">
            <span className="text-base font-bold">Facebook</span>
            <span className="md:text-sm text-xs text-[#475569]">
              Sign in with Facebook and discover your trusted connections to
              buyers
            </span>
          </div>
          <div>
            <Button size="sm" variant="light-blue">
              <LinkIcon /> Connect
            </Button>
          </div>
        </div>

        <div className="w-full flex justify-between items-center">
          <div className="flex flex-col">
            <span className="text-base font-bold">Google</span>
            <span className="md:text-sm text-xs text-[#475569]">
              Sign in with Google and discover your trusted connections to
              buyers
            </span>
          </div>
          <div>
            <Button size="sm" variant="light-blue">
              <LinkIcon /> Connect
            </Button>
          </div>
        </div>
        <div className="border border-[#E2E8F0] px-[-30px] my-3 mx-[-25px]" />
        <div className="flex flex-col gap-3">
          <span className="text-sm text-[#475569] font-bold">
            Are you sure you want to delete your account?
          </span>
          <div>
            <Button
              variant="destructive"
              size="sm"
              onClick={() => setShowDeleteModal(true)}
            >
              Yes, Delete my account
            </Button>
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <ConfirmDialog
            deleting={isPending}
            handleDelete={() => {
              handleDeleteProfile();
            }}
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
          />
        </div>
      )}
    </div>
  );
};
