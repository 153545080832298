import React, { Suspense } from "react";
import { Layout } from "@component";
import { Route } from "react-router-dom";
import { AuthGuard } from "shared";
import { routerType } from "types";

export const Router = ({ element, ...route }: routerType) => {
  const { useAuth } = route;

  return (
    <Route
      key={route.title}
      path={`/${route.path}`}
      element={
        <Suspense fallback={<div>Loading...</div>}>
          <Layout>
            {useAuth ? <AuthGuard>{element}</AuthGuard> : element}
          </Layout>
        </Suspense>
      }
    />
  );
};
