import React, { useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import { IconAdCircle } from "@tabler/icons-react";
import {
  checkIfFilesAreTooBig,
  checkIfImagesAreCorrectType,
} from "utils/helper";
import { notify } from "shared/notifications/notify";
import { SliderWrapper } from "./sliderWrapper";
import ConfirmDialog from "./confirmDialog";
import { DeleteIcon, Plus, PlusCircle, PlusIcon } from "lucide-react";

type Props = {
  name: string;
  id: string;
  snapType: string;
  variant?: "type1" | "type2";
  isMultiple?: boolean;
  uploadedPreviews?: string[];
  productId?: string;
  previewUrl?: string[];
};

function FileUploader({
  name,
  id,
  snapType,
  variant = "type1",
  isMultiple = false,
  uploadedPreviews = [],
  productId,
  previewUrl,
}: Props) {
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [s3Path, setS3Path] = useState("");
  const { register, setValue } = useFormContext();
  const queryClient = useQueryClient();

  // State to store the selected files
  const [files, setFiles] = useState<File[]>(() => []);
  // State to store the previews of selected files
  const [previews, setPreviews] = useState<string[]>(() => previewUrl ?? []);

  useEffect(() => {
    // Ensure the form's default values are set up correctly
    setValue(name, []);
  }, [setValue, name]);

  // Effect to generate previews for selected files
  useEffect(() => {
    if (!files.length) return;

    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setPreviews(newPreviews);

    // Cleanup function to revoke object URLs and avoid memory leaks
    return () => {
      newPreviews.forEach((preview) => URL.revokeObjectURL(preview));
    };
  }, [files]);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newFiles = event.target.files ? Array.from(event.target.files) : [];
    if (!checkIfImagesAreCorrectType(newFiles)) {
      notify.error({
        message: "Only images(png,jpg,jpeg) are allowed",
        type: "error",
      });
      return;
    }
    if (!checkIfFilesAreTooBig(newFiles, 2)) {
      notify.error({
        message: "File must be at most 2mb",
        type: "error",
      });
      return;
    }
    setValue(name, newFiles);
    setFiles(newFiles);
    return;
  };

  // Helper function to render the file upload UI based on the current state
  const renderUploadUI = () => {
    if (previews.length) {
      return isMultiple && variant === "type2" ? (
        <SliderWrapper
          slides={previews.map((preview, index) => (
            <div
              key={Math.random()}
              className="relative isolate flex h-full min-h-[200px] w-full items-center justify-center rounded-md "
            >
              <span className=" w-fit rounded-full bg-white p-2  transition-all duration-300 ease-in-out hover:scale-[1.2]">
                {<PlusCircle />}
              </span>
              <img
                src={preview}
                alt={`Preview ${index + 1}`}
                // layout="fill"
                className="z-[-1] rounded-[inherit] object-cover"
                // unoptimized
              />
            </div>
          ))}
        />
      ) : (
        <div className="relative h-full w-full rounded-[inherit]">
          {previews[0] || previewUrl ? (
            <div className=" h-full w-full rounded-[inherit] ">
              <img
                src={(previews[0] as string) || (previewUrl as string[])[0]}
                // fill
                className="rounded-[inherit] object-cover"
                alt="Captured"
                // priority
              />
              <div className="absolute top-1/2 flex h-full w-full -translate-y-1/2 items-center justify-center rounded-[inherit] bg-black/40">
                <div className="up-btn flex items-center gap-2">
                  <small className="text-white">
                    Re-Upload document {snapType}
                  </small>
                  <span>upload icon</span>
                </div>
              </div>
            </div>
          ) : (
            <div className=" flex  w-full rounded-[inherit] ">
              <span className="text-sm text-white">{files[0]?.name}</span>
              <div className="absolute top-1/2 flex h-full w-full -translate-y-1/2 items-center justify-center rounded-[inherit] bg-black/40">
                <div className="up-btn flex items-center gap-2">
                  <small className="text-white">
                    Re-Upload document {snapType}
                  </small>
                  <span>upload icon</span>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      // Render the initial upload UI if no files are selected
      return variant === "type2" ? (
        // <div className="relative isolate flex h-full w-full items-center justify-center  rounded-[inherit]">
        // <span className=" w-fit rounded-full bg-white p-2  transition-all duration-300 ease-in-out hover:scale-[1.2]">
        //   plus iconmm
        // </span>
        // <img
        //   src={"/Frontend/audtik-frontend/src/assets/images/blogimg.png"}
        //   // fill
        //   className="z-[-1] rounded-[inherit] "
        //   alt="Captured"
        //   // unoptimized
        // />
        // </div>

        <div className="up-btn  border border-dashed border-[#94A3B8] flex  w-full items-center justify-center rounded-[inherit] bg-[#F1F5F9] h-full min-h-[200px]">
          <div className="up-btn flex-col w-full flex items-center gap-2">
            <div className=" w-[clamp(80px_8vw_80px)] rounded-full bg-white p-2  transition-all duration-300 ease-in-out hover:scale-[1.2]">
              {/* plus */} <Plus />
            </div>
            <div className="flex flex-col items-center">
              <span className=" text-sm font-bold">Upload Image</span>
              <small className="text-[#808080]">upto 5mb {snapType}</small>
            </div>
            <img
              src={"/Frontend/audtik-frontend/src/assets/images/blogimg.png"}
              // fill
              className="z-[-1] rounded-[inherit] "
              alt="Captured"
              // unoptimized
            />
          </div>
        </div>
      ) : (
        <div className="up-btn flex h-full w-full items-center justify-center rounded-[inherit] bg-[rgba(222,242,251,0.3)]">
          <div className="up-btn flex items-center gap-2">
            <small className="text-[#808080]">Upload document {snapType}</small>
            <span>upload icon</span>
          </div>
        </div>
      );
    }
  };
  function handleDelete(): void {
    throw new Error("Function not implemented.");
  }

  // const handleDelete = async () => {
  //   if (s3Path === "") notify.error({ message: "Invalid s3path path" });
  //   setDeleting(true);
  //   console.log(uploadedPreviews.filter((p) => p !== s3Path));
  //   try {
  //     await deleteImageSingle(s3Path);
  //     await updateProduct({
  //       id: productId ?? "",
  //       data: { pictures: [...uploadedPreviews.filter((p) => p !== s3Path)] },
  //     });
  //     queryClient.invalidateQueries({
  //       queryKey: [QUERY_KEYS.PRODUCT],
  //     });
  //     notify.success({ message: "image deleted successfully" });
  //     setShowModal(false);
  //   } catch (error) {
  //     notify.error({
  //       message: "Unable to delete image",
  //     });
  //   } finally {
  //     setDeleting(false);
  //   }
  // };
  return (
    <>
      <div
        className={classNames(
          "mt-3 min-h-[96px] h-full w-full  rounded-md border border-dashed border-[#272424]",
          variant === "type2" && "border-0",
        )}
      >
        <input
          {...register(name)}
          type="file"
          accept="image/*"
          onChange={handleFileInputChange}
          className="hidden"
          multiple={isMultiple}
          id={id}
        />

        {uploadedPreviews?.length > 0 ? (
          <div className="h-full w-full rounded-[inherit]">
            <SliderWrapper
              slides={[
                ...uploadedPreviews.map((preview, index, array) => (
                  <div
                    key={Math.random()}
                    className="relative isolate flex h-[372px] w-full items-center justify-center rounded-md"
                  >
                    {array.length < 2 ? (
                      ""
                    ) : (
                      <button
                        onClick={() => {
                          setS3Path(preview);
                          return setShowModal(true);
                        }}
                        type="button"
                        className="  cursor-pointer w-[100px] h-[200px] rounded-full bg-red-800 p-2 transition-all duration-300 ease-in-out hover:scale-[1.2]"
                      >
                        {/* delete icon */}
                        <DeleteIcon />
                      </button>
                    )}
                    <img
                      src={preview}
                      alt={`Preview ${index + 1}`}
                      // layout="fill"
                      className="z-[-1] rounded-[inherit] object-cover"
                    />
                  </div>
                )),
                ...previews.map((preview, index) => (
                  <label
                    htmlFor={id}
                    key={preview}
                    className="relative h-[372px] w-full cursor-pointer rounded-[inherit]  "
                  >
                    <div
                      key={Math.random()}
                      className="relative isolate flex h-[372px] w-full items-center justify-center rounded-md"
                    >
                      <span className=" w-fit rounded-full bg-white p-2  transition-all duration-300 ease-in-out hover:scale-[1.2]">
                        {/* plus icon */} <PlusIcon />
                      </span>
                      <img
                        src={
                          "/Frontend/audtik-frontend/src/assets/images/blogimg.png"
                        }
                        alt={`Preview ${index + 1}`}
                        // layout="fill"
                        className="z-[-1] rounded-[inherit] object-cover"
                        // unoptimized
                      />
                    </div>
                  </label>
                )),
                ...Array(1)
                  .fill(0)
                  .map((i) => (
                    <label
                      htmlFor={id}
                      key={Math.random() * 3}
                      className=" relative h-full w-full cursor-pointer rounded-[inherit]  bg-red-600"
                    >
                      <div className="relative isolate flex h-[372px] w-full items-center justify-center  rounded-[inherit]">
                        <span className=" w-fit rounded-full bg-white p-2  transition-all duration-300 ease-in-out hover:scale-[1.2]">
                          [plus icon]
                        </span>
                        <img
                          src={
                            "/Frontend/audtik-frontend/src/assets/images/blogimg.png"
                          }
                          // fill
                          className="z-[-1] rounded-[inherit]  "
                          alt="Captured"
                          // unoptimized
                        />
                      </div>
                    </label>
                  )),
              ]}
            />
          </div>
        ) : (
          <label
            htmlFor={id}
            className="relative h-full  w-full cursor-pointer rounded-[inherit]"
          >
            {renderUploadUI()}
          </label>
        )}
      </div>
      {/* <Drawer
        open={showModal}
        onClose={() => setShowModal(!showModal)}
        title=""
        selector="bundo-app-portal"
        anchor="center"
        z="z-50"
        h="h-fit"
        bg="bg-transparent"
        borderRadius="rounded-lg"
        widthStyles="w-[500px] md:w-[500px]"
        isFooter={false}
        containerClassName={"grid-rows-[auto_1fr]"}
      > */}
      {/* <ConfirmDialog
        deleting={deleting}
        handleDelete={handleDelete}
        showModal={showModal}
        setShowModal={setShowModal}
        confirmationMessage="Are you sure you want to remove this image?"
      /> */}
      {/* </Drawer> */}
    </>
  );
}

export default FileUploader;
