/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { Avatar } from "ui/avatar";
import { Button } from "ui/button";
import { Card } from "ui/card";
import HeartIcon from "assets/svg/heart.svg";
import { UsersLayout, SideSearchView } from "@component";
import { useGetCategory } from "hooks/services";
import { useGetServiceProfile } from "hooks/profile/useGetServiceProfile";
import { useNavigate } from "react-router-dom";
// import { useGetCategory } from "hooks/services/useGetCategory";

export const SearchPage = () => {
  const { categoryData } = useGetCategory();
  const { serviceProfileData, serviceProfileLoading } = useGetServiceProfile();
  const navigate = useNavigate();

  const numResult =
    categoryData?.results?.length! > 0 ? categoryData?.results?.length! - 1 : 0;

  return (
    <>
      <div className="mt-20 flex flex-col container">
        <div className="flex flex-col gap-3 px-4 ">
          <span className="font-bold">
            Service Provider Results ({numResult})
          </span>
          <span className="text-sm">
            Didn't find your desired Skill Provider,
          </span>
        </div>
        {/* <SearchFilterContainer /> */}

        <UsersLayout sideChildren={<SideSearchView />}>
          <div className="mt-4">
            <span>
              <span className="font-bold">Results </span>({numResult})
            </span>
            <div>
              {numResult === 0 ? (
                <div className="flex text-3xl font-bold justify-center items-center h-[400px]">
                  No result found
                </div>
              ) : null}
            </div>
            <div className="flex flex-col gap-8 mt-5">
              {!serviceProfileLoading &&
                serviceProfileData &&
                serviceProfileData?.results?.map((service) => (
                  <div
                    className="grid grid-cols-1 md:grid-cols-5 gap-8 border-[#4188F9] border-b-8  shadow-lg py-4"
                    key={service?.id}
                  >
                    <div className="col-span-2 relative">
                      <Card className="max-w-[clamp(200px_8vw_200px)] w-full">
                        <img src={service?.profile_picture} alt="garden Img " />
                      </Card>
                      <div className="absolute top-2 right-7 lg:top-2 lg:right-2 p-3 rounded-[10px] w-[clamp(30px_8vw_80px)] bg-white">
                        <img src={HeartIcon} alt="heart icon" />
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 col-span-3 ">
                      <div className="flex items-center gap-2">
                        <Avatar className="bg-[#D9D9D9] w-[clamp(50px,_8vw,_50px)]" />
                        <div className="w-full">
                          <span className="font-bold">
                            {service?.business_name}
                          </span>
                          <div className="flex w-full justify-between">
                            <div>
                              <span>
                                {service?.state}, {service?.country} - Km away
                              </span>
                            </div>
                            <div>
                              <span>5.0</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span>{service?.about_me}</span>
                      <span>
                        From <span className="font-bold">$25/hr</span>
                      </span>
                      <div>
                        <Button
                          size="sm"
                          className=" px-16"
                          onClick={() =>
                            navigate(`/product-details/${service?.id}`)
                          }
                        >
                          Hire me
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </UsersLayout>
      </div>
    </>
  );
};
