import React from "react";
import CustomAvatar from "./CustomAvatar";

const SellerChatBox = ({
  textContent,
  sender,
  timestamp,
}: {
  textContent: string;
  sender: string;
  timestamp: string;
}) => {
  return (
    <div className="flex flex-col mt-2 px-4">
      <div className="flex items-center gap-2 bg-accents-100  justify-start">
        {/* <Image
          src="/images/profile.svg"
          alt="Profile image"
          height={0}
          width={0}
          className="h-8 w-8 object-cover rounded-full"
        /> */}
        <CustomAvatar username={sender} />
        <div>
          <p className="bg-accents-300 p-3 border rounded-lg">{textContent}</p>
          <div className="text-left flex justify-start text-[8px] gap-2 ">
            <span>{sender}</span>
            <span>{timestamp}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerChatBox;
