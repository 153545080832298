import React from "react";

const DesktopChatBox = () => {
  return (
    <div className="flex h-[550px] justify-center items-center bg-accents-100 pt-4 mt-4">
      <div>
        <span>Start Conversation</span>
      </div>
    </div>
  );
};

export default DesktopChatBox;
