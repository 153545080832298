import React from "react";
import DesktopSIdebar from "./DesktopSIdebar";
import DesktopChatBox from "./DesktopChatBox";

const DesktopMessage = () => {
  return (
    <div className="grid grid-cols-[300px_1fr] gap-4">
      <div>
        <DesktopSIdebar />
      </div>
      <div>
        <DesktopChatBox />
      </div>
    </div>
  );
};

export default DesktopMessage;
