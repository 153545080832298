/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from "react";
import { ReactComponent as SocialIcon1 } from "assets/svg/socialIcon1.svg";
import { ReactComponent as SocialIcon2 } from "assets/svg/socialIcon2.svg";
import { ReactComponent as SocialIcon3 } from "assets/svg/socialIcon3.svg";
import { ReactComponent as SocialIcon4 } from "assets/svg/socialIcon4.svg";

interface FooterProps {
  prop1: `string`;
}

const data = [
  { title: "connect", item1: "namehere@gmail.com", item2: "+44 124 568 7890" },
  {
    title: "Quick links",
    item1: "Home",
    item2: "Post a job",
    item3: "My jobs",
  },
  {
    title: "Digital Services",
    item1: "Name here",
    item2: "Name here",
    item3: "Name here",
    item4: "Name here",
  },
  {
    title: "Home Services",
    item1: "Name here",
    item2: "Name here",
    item3: "Name here",
    item4: "Name here",
  },
  {
    title: "Follow",
    item1: [<SocialIcon1 />, <SocialIcon2 />, <SocialIcon3 />, <SocialIcon4 />],
  },
];
export const Footer: FC<FooterProps> = () => {
  return (
    <>
      <section className="bg-[#17191A] text-white w-full flex justify-center">
        <div className="container w-full py-16 gap-6 lg:gap-0 px-6 xlg:px-0 grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 ">
          {data?.map((v) => (
            <div className="flex flex-col gap-4" key={Math.random()}>
              <span className="text-[#9E9E9F] ">{v.title}</span>
              <div className="flex flex-col text-sm md:text-base text-white gap-3">
                {v?.title == "Follow" ? (
                  <div className="w-full items-center grid grid-cols-4 gap-14 md:gap-0">
                    {//@ts-expect-error
                    v?.item1?.map((v: any, idx) => (
                      <div
                        key={idx}
                        className="bg-[#27272A] isolate flex aspect-square w-[clamp(45px,_8vw,_45px)]  items-center justify-center rounded-full"
                      >
                        <div className=" ">{v}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <span>{v.item1}</span>
                )}
                <span>{v.item2}</span>
                <span>{v.item3}</span>
                <span>{v.item4}</span>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="bg-white w-full">
        <div className="container w-full flex justify-center flex-wrap md:justify-between gap-2 py-3 px-6 xlg:px-0">
          <span className="font-medium text-sm lg:text-base ">
            Copyrights 2024. All rights reserved
          </span>
          <div className="flex items-center">
            {["Privacy policy", "Terms & conditions", "Sitemap", "Legal"]?.map(
              (v, i) => (
                <div key={i} className="flex items-center ">
                  <span
                    key={Math.random()}
                    className="font-medium text-sm lg:text-base whitespace-nowrap"
                  >
                    {v}
                  </span>
                  {i !== 3 && <span className="text-[#B7B8B8] px-1">•</span>}
                </div>
              )
            )}
          </div>
        </div>
      </section>
    </>
  );
};
