// import logo1 from "../../../../assets/images/logo1.png";
// import logo2 from "../../../../assets/images/logo2.png";
// import logo3 from "../../../../assets/images/logo3.png";
// import logo4 from "../../../../assets/images/logo4.png";
// import logo5 from "../../../../assets/images/logo5.png";
// import logo6 from "../../../../assets/images/logo6.png";

import {
  CarouselContainer,
  Logo,
  LogosContainer,
  LogosCarouselDiv,
} from "./LogosCarouselStyles";
import { Paragraph, logo1, logo2, logo3, logo4, logo5, logo6 } from "assets";
import { ReactComponent as Logo1 } from "assets/svg/logo1.svg";
import { ReactComponent as Logo2 } from "assets/svg/logo2.svg";
import { ReactComponent as Logo3 } from "assets/svg/logo3.svg";
import { ReactComponent as Logo4 } from "assets/svg/logo4.svg";

export const LogosCarousel = () => {
  return (
    <div className=" flex w-full items-center py-10 gap-8 flex-col">
      <span className="font-semibold md:text-base text-center text-sm ">
        Trusted by 180,000+ customers worldwide
      </span>
      <div className="w-full overflow-hidden flex">
        <LogosContainer>
          <Logo1 className=" inline-block mx-10" />
          <Logo2 className=" inline-block mx-10" />
          <Logo3 className=" inline-block mx-10" />
          <Logo4 className=" inline-block mx-10" />
          <Logo1 className=" inline-block mx-10" />
          <Logo2 className=" inline-block mx-10" />
        </LogosContainer>
        <LogosContainer>
          <Logo1 className=" inline-block mx-10" />
          <Logo2 className=" inline-block mx-10" />
          <Logo3 className=" inline-block mx-10" />
          <Logo4 className=" inline-block mx-10" />
          <Logo1 className=" inline-block mx-10" />
          <Logo2 className=" inline-block mx-10" />
        </LogosContainer>
      </div>
    </div>
  );
};
