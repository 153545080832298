import React, { SyntheticEvent, useState } from "react";
import SearchIcon from "./SearchIcon";
import CustomAvatar from "./CustomAvatar";
import { Link, useParams } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import BuyerChatBox from "./BuyerChatBox";
import SellerChatBox from "./SellerChatBox";
import SendIcon from "./SendIcon";
import { useGetPreviousConvo } from "hooks/services/useGetPreviousConvo";
import { useMakeConversation } from "hooks/services/useMakeConversation";
import { useQueryClient } from "@tanstack/react-query";
import { formatTime } from "utils";
import { useAuthenticatedUser } from "hooks";

const ChatContent = () => {
  const [msg, setMsg] = useState("");
  const [searchConvo, setSearchConvo] = useState("");
  const queryClient = useQueryClient();
  const { userDetails } = useAuthenticatedUser();

  const { id } = useParams();
  const { isLoading, prevConvo } = useGetPreviousConvo(id as string);

  console.log(prevConvo, "prevConvo");

  const { makeConvo } = useMakeConversation();

  // const filteredConversation =

  const handleSendMsg = async (e: SyntheticEvent) => {
    e.preventDefault();

    const paylaod = {
      id,
      content: msg,
    };

    const res = await makeConvo(paylaod);

    if (res?.success) {
      queryClient.invalidateQueries({
        queryKey: ["messages"],
      });
    }

    console.log(res, "sending");

    //   sendMsgMutation(payload);s
    setMsg("");
  };

  return (
    <div>
      <h1 className="md:hidden text-base flex items-center gap-2 font-normal text-accents-800 p-[16px_14px_12px_14px]">
        <Link to="/chat">
          <ArrowLeft />
        </Link>
        <span>{"Chat"}</span>
      </h1>
      <div className="bg-accents-300 px-4 py-4">
        <div className="bg-accents-100 rounded-lg ">
          <div className="border-b-accents-300 border-b flex items-center px-4 justify-between">
            <div className="flex items-center gap-2 p-3  ">
              {/* <Image
                src="/images/profile.svg"
                alt="Profile image"
                height={0}
                width={0}
                className="h-8 w-8 object-cover rounded-full"
              /> */}
              <CustomAvatar
                username={userDetails?.data?.fullname}
                src={userDetails?.data.profile_picture}
              />

              <div className="flex flex-col">
                <span className="text-xs text-nowrap">{"Pascal"}</span>
                <p className="flex items-center gap-1 text-xs">
                  <span className="text-accents-700 text-nowrap">
                    {"activeNow"}
                  </span>
                  <span>{/* <GreenDot /> */}</span>
                </p>
              </div>
            </div>
            {/* <SearchIcon /> */}
            <div className="bg-accents-100">
              <div
                className={`flex  w-full items-center rounded-[9px] border border-dark-light px-[16px]  relative  bg-accents-100`}
              >
                <SearchIcon className=" right-4 bg-transparent text-white bg-white" />

                <input
                  type="text"
                  onChange={(e) => setSearchConvo(e.target.value)}
                  value={searchConvo}
                  className={`w-full borde border-accents-500 bg-accents-100 px-3 py-2 text-md  rounded-lg file:text-sm file:font-medium placeholder:text-accents-500 placeholder:text-[10px] md:placeholder:text-sm focus-visible:outline-none focus-visible:ring-primary focus-visible:ring-0 dark:focus-visible:ring-primary dark:placeholder:text-accents-500 disable:opacity-50 disabled:cursor-not-allowed h-10 flex file:border-0`}
                />
              </div>
            </div>
          </div>
          {/* <div className="bg-accents-100">
            <SearchInput iconFirst placeholder="search" />
          </div> */}
          <div className=" relative flex flex-col justify-between gap-4">
            <div className="bg-accents-100 relative h-[400px] scrollbar-hide  overflow-auto">
              {prevConvo &&
                prevConvo?.data?.map((convo) => {
                  return (
                    <>
                      {convo?.is_sender ? (
                        <BuyerChatBox
                          textContent={convo?.content}
                          //   sender={currentUser?.data?.name as string}
                          sender={userDetails?.data?.fullname as string}
                          // timestamp={formatTime(
                          //   convo?.createdAt,
                          //   params.locale
                          // )}
                          timestamp={formatTime(convo?.updated_at)}
                          key={convo?.id}
                        />
                      ) : (
                        <SellerChatBox
                          textContent={convo?.content}
                          sender={"" as string}
                          timestamp={formatTime(convo?.updated_at)}
                          key={convo?.id}
                        />
                      )}
                    </>
                  );
                })}
            </div>
            <section className="  w-full">
              <form className="p-3" onSubmit={handleSendMsg}>
                <div
                  className={`flex items-center justify-between rounded-md border-2 border-dark-very-dark p-3 ${
                    // errorMessages ? " border border-red-500" : ""
                    ""
                  }`}
                >
                  <input
                    type="text"
                    className={`w-full  bg-transparent text-dark outline-none`}
                    // placeholder={t("my_message.type_message")}
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                  />
                  <button
                    className="border-none outline-none"
                    type="submit"
                    disabled={!msg}
                    onClick={() => {
                      // socket.emit()
                    }}
                  >
                    <SendIcon />
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatContent;
