import React from "react";
import CustomAvatar from "./CustomAvatar";

const BuyerChatBox = ({
  sender,
  textContent,
  timestamp,
}: {
  timestamp: string;
  sender: string;
  textContent: string;
}) => {
  return (
    <div className="flex flex-col mt-2 px-4">
      <div className="flex items-center gap-2 bg-accents-100  justify-end">
        <div>
          <p className="bg-accents-300 p-3 border rounded-lg">{textContent}</p>
          <div className="text-right flex  text-[8px] gap-2">
            <span>{sender}</span>
            <span>{timestamp}</span>
          </div>
        </div>
        <CustomAvatar username={sender} />
      </div>
    </div>
  );
};

export default BuyerChatBox;
