import { useGetCategory } from "hooks/services/useGetCategory";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button } from "ui/button";
import { CategoriesSort } from "ui/categoriesSort";
import { Checkbox } from "ui/checkBox";
import { ReactComponent as FilterIcon } from "assets/svg/filtericon.svg";
import { ICategoryData } from "types";

export const SideSearchView = () => {
  const method = useForm();
  const { categoryData } = useGetCategory();

  const items = categoryData?.results?.map((item) => ({
    id: item?.category,
    name: item?.name,
  }));

  const handleFilter = () => {
    console.log("clcik");
  };

  return (
    <div className="w-full h-full">
      <div className="px-3 my-4 sticky bg-white w-full left-0 right-0 top-0 z-10 bottom-0 border-b border-[#E2E8F0] pb-3 pt-2 flex justify-between items-center">
        <span className="text-sm font-bold">Recent Jobs</span>
        <div>
          {" "}
          <FilterIcon />
        </div>
      </div>
      <div className="flex flex-col">
        <span className="px-3 font-bold text-sm">Category</span>
        <div className="p-3 flex flex-col gap-3">
          <CategoriesSort
            items={items as any}
            filters={items as any[]}
            // filters={[]}
            setFilters={() => {}}
            className="w-fit gap-3 flex flex-wrap text-black"
            textColor="text-black border-[#E2E8F0]"
            label="category"
          />
          <Link to="" className="text-sm underline">
            See more
          </Link>
        </div>
        <div className="w-full relative inset-0 border my-3 shadow-sm px-[-60px] hidden lg:block" />
        {/* <div className="flex flex-col px-3">
          <span>Availability</span>
          <div className="flex flex-col gap-3">
            <div className="mt-3">
              <FormProvider {...method}>
                <form className="flex flex-col justify-center gap-3">
                  {["Morning", "Afternoon", "Evening"].map((v) => (
                    <div className="flex items-center gap-2">
                      <Checkbox name={"check"} />
                      <span className="text-sm">{v} (8am-12pm)</span>
                    </div>
                  ))}
                </form>
              </FormProvider>
            </div>
            <div className="flex items-center h-full gap-2 mt-2">
              <div className="w-full relative inset-0 border shadow-sm px-[-60px] hidden lg:block" />{" "}
              <span className="flex flex-nowrap whitespace-nowrap text-sm">
                {" "}
                or choose a specific name
              </span>{" "}
              <div className="w-full relative inset-0 border  shadow-sm px-[-60px] hidden lg:block" />
            </div>
          </div>
        </div> */}
        <div className="w-full relative inset-0 border my-6  shadow-sm px-[-60px] hidden lg:block" />

        <div className="flex flex-col gap-3">
          <span className="px-3 font-bold text-sm">Skills</span>
          <div className="p-3 flex flex-col gap-3">
            <CategoriesSort
              items={[
                {
                  name: "Web designs",
                  id: "0988999888292929",
                },
              ]}
              filters={[]}
              setFilters={() => {}}
              className="w-fit gap-3 flex flex-wrap text-black"
              textColor="text-black border-[#E2E8F0]"
              label="skill"
            />
            <Link to="" className="text-sm underline">
              See more
            </Link>
          </div>
        </div>
        <div className="w-full relative inset-0 border my-6  shadow-sm px-[-60px] hidden lg:block" />

        <div className="mx-3">
          <span className="text-[#475569] font-bold">Price Range</span>
        </div>
      </div>
    </div>
  );
};
