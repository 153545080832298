/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { BsDashLg } from "react-icons/bs";
import { LuDot } from "react-icons/lu";
import { Paragraph, blogimg } from "assets";
import { AdzCard } from "ui/adzCard";
import { ExploreServices } from "data/ExploreData";
import { Link } from "react-router-dom";
import { ReactComponent as ReadMoreIcon } from "assets/svg/arrow-down.svg";

export const Blog = () => {
  return (
    <div className="container px-6 w-full xlg:px-0 py-8">
      <div className="flex w-full flex-wrap justify-between pt-10 pb-4">
        <div className="flex flex-col gap-3">
          <div className="flex gap-3 items-center">
            <div className="border-b border-2 border-black w-6" />{" "}
            <span className="text-sm uppercase font-semibold">
              Frequently Asked Questions
            </span>
          </div>
          <span className="md:text-[42px] text-[22px] capitalize font-bold leading-[50px] lg:leading-[60px]">
            Check out our Blogs
          </span>
        </div>
        <div>
          <span className="text-base text-[#334155]">
            We're connecting people to resources, places and <br /> communities
            that can help them thrive and live a good life.
          </span>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2">
        {Array(4)
          .fill(0)
          .map((_) => (
            <div key={Math.random()}>
              <AdzCard
                img={blogimg}
                description="Lorem ipsum dolor sit amet, conetur adipiscing elit. Sed congue erat non ante porttitor tincidunt."
                showButton={
                  <Link
                    to=""
                    className="text-[#4188F9] flex items-center text-sm font-medium underline mt-[-15px]"
                  >
                    ReadMore <ReadMoreIcon className="w-5 font-medium" />
                  </Link>
                }
                date="Nov 12, 2023 • 11:45 PM"
              />
            </div>
          ))}
      </div>
    </div>
  );
};

{
  /* <BlogContainer>
<DashedTitle>
  <BsDashLg /> Our Blogs
</DashedTitle>
<BlogTop>
  <BlogHeader>Check out our blogs</BlogHeader>
  <Paragraph>
    We're connecting people to resources, places and communities that can
    help them thrive and live a good life.
  </Paragraph>
</BlogTop>

<Blogs>
  <BlogItem>
    <BlogImg src={blogimg} />
    <BlogDate>
      Nov 12, 2023 <LuDot /> 11:45 PM{" "}
    </BlogDate>
    <BlogInfo>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla,
      mollitia sapiente ratione totam sed eum vero reprehenderit
      aspernatur nobis aperiam quia similique! Fugit, eligendi molestias
      vel eum iure nulla numquam.
    </BlogInfo>
    <BlogReadMore>
      Read More <MdArrowOutward />
    </BlogReadMore>
  </BlogItem>

  <BlogItem>
    <BlogImg src={blogimg} />
    <BlogDate>
      Nov 12, 2023 <LuDot /> 11:45 PM{" "}
    </BlogDate>
    <BlogInfo>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla,
      mollitia sapiente ratione totam sed eum vero reprehenderit
      aspernatur nobis aperiam quia similique! Fugit, eligendi molestias
      vel eum iure nulla numquam.
    </BlogInfo>
    <BlogReadMore>
      Read More <MdArrowOutward />
    </BlogReadMore>
  </BlogItem>

  <BlogItem>
    <BlogImg src={blogimg} />
    <BlogDate>
      Nov 12, 2023 <LuDot /> 11:45 PM{" "}
    </BlogDate>
    <BlogInfo>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla,
      mollitia sapiente ratione totam sed eum vero reprehenderit
      aspernatur nobis aperiam quia similique! Fugit, eligendi molestias
      vel eum iure nulla numquam.
    </BlogInfo>
    <BlogReadMore>
      Read More <MdArrowOutward />
    </BlogReadMore>
  </BlogItem>

  <BlogItem>
    <BlogImg src={blogimg} />
    <BlogDate>
      Nov 12, 2023 <LuDot /> 11:45 PM{" "}
    </BlogDate>
    <BlogInfo>
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nulla,
      mollitia sapiente ratione totam sed eum vero reprehenderit
      aspernatur nobis aperiam quia similique! Fugit, eligendi molestias
      vel eum iure nulla numquam.
    </BlogInfo>
    <BlogReadMore>
      Read More <MdArrowOutward />
    </BlogReadMore>
  </BlogItem>
</Blogs>
</BlogContainer> */
}
