import { IconProps } from "assets/Icons/IconProps";
import React, { SVGProps } from "react";

const SearchIcon = ({ color = "#818B80", ...rest }: IconProps) => {
  return (
    <>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M11.25 11.25L9.20839 9.20833M10.6667 5.70833C10.6667 8.44675 8.44675 10.6667 5.70833 10.6667C2.96992 10.6667 0.75 8.44675 0.75 5.70833C0.75 2.96992 2.96992 0.75 5.70833 0.75C8.44675 0.75 10.6667 2.96992 10.6667 5.70833Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default SearchIcon;
