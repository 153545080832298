import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { io } from "socket.io-client";

export const useSocket = (id: string) => {
  const queryClient = useQueryClient();
  // const { currentUser } = useGetCurrentUser();
  let currentUser: any;
  // const [cookies] = useCookies(["auth"]);

  const socket = io(process.env.NEXT_PUBLIC_BASE_API as string, {
    path: "/socket",
    extraHeaders: {
      // Authorization: `Bearer ${cookies?.auth?.accessToken}`,
    },
  });

  const unreadMessage = `unread_messages_${
    currentUser && (currentUser?.data?.id as string)
  }`;
  const newMessages = `new_message_${
    currentUser && (currentUser?.data?.id as string)
  }`;
  const newNotification = `new_notifications_${
    currentUser && (currentUser?.data?.id as string)
  }`;
  const unreadNotification = `unread_notifications_${
    currentUser && (currentUser?.data?.id as string)
  }`;
  const unreadMessageFrom = `unread_messages_from_${id && (id as string)}`;
  const newMessageFrom = `new_message_from_${id && (id as string)}`;

  useEffect(() => {
    if (!currentUser?.data?.id) return;
    socket.on(unreadMessage, (unreadMessage) => {
      // setMessagesCount(unreadMessage);
    });

    socket.on(newMessages, () => {
      queryClient.invalidateQueries({
        queryKey: ["conversation", currentUser?.data?.id],
      });
    });
    socket.on(newNotification, () => {
      queryClient.invalidateQueries({
        queryKey: ["notification", currentUser?.data?.id],
      });
    });
    socket.on(unreadNotification, () => {
      queryClient.invalidateQueries({
        queryKey: ["notification", currentUser?.data?.id],
      });
    });

    socket.on(newMessageFrom, () => {
      queryClient.invalidateQueries({
        queryKey: ["conversation", id],
      });
    });

    socket.on(unreadMessageFrom, (unreadMessageFrom) => {
      console.log(unreadMessageFrom, "unread msg");
    });

    return () => {
      socket.off(unreadMessage);
      socket.off(newMessages);
      socket.off(newMessageFrom);
      socket.off(unreadMessageFrom);
      socket.disconnect();
    };
  }, [
    socket,
    currentUser?.data?.id,
    unreadMessage,
    newMessages,
    newMessageFrom,
    unreadMessageFrom,
    queryClient,
    id,
    newNotification,
    unreadNotification,
    //   setMessagesCount,s
  ]);

  return socket;
};
