import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

import React from "react";
import { stringToColor } from "shared";

const CustomAvatar = ({
  src,
  username,
}: {
  src?: string;
  username?: string;
}) => {
  const color = stringToColor(username || "AUT");
  const defaultString = "fmx";
  const splitedName = username?.split(" ");
  const customName = splitedName
    ? `${splitedName[0][0]} ${splitedName[0][1]}`
    : defaultString?.slice(0, 2);
  const customName2 =
    splitedName && splitedName?.length > 1
      ? `${splitedName[0][0]} ${splitedName[1][0]}`
      : defaultString?.slice(0, 2);
  return (
    <Avatar>
      <AvatarImage src={src} alt="@shadcn" />
      <AvatarFallback
        style={{
          backgroundColor: color,
        }}
        className="text-accents-100 uppercase"
      >
        {splitedName && splitedName?.length > 1 ? customName2 : customName}
      </AvatarFallback>
    </Avatar>
  );
};

export default CustomAvatar;
