// import {ReactComponent as Logo} from 'assets/images/logo.png';
import { SelectIcon } from "assets/svg";
import { useContext, useEffect, useRef, useState } from "react";
import { LayoutContext } from "context/LayoutContext";
import { logOut } from "utils/auth/auth";
import { useQuery } from "@tanstack/react-query";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
// import { Link, useLocation, useNavigate } from "react-router-dom";
import { routes } from "routes";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import aLlogo, { ReactComponent as Logo } from "assets/svg/alLogo.svg";
import { Button } from "ui/button";
import { ReactComponent as HamburgerMenu } from "assets/svg/menu.svg";
import { Avatar } from "ui/avatar";
import { X } from "lucide-react";
import { DesktopFilter } from "./DesktopFilter";
import MobileFilter from "./MobileFilter";
import { ForgotPassword, LoginForm, SignUp } from "pages";
import { Input } from "ui/input";
// import { useLocation, useNavigate } from "react-router";
// import { Link, useSearchParams } from "react-router-dom";

export const Navbar = ({
  handleOpenSignin,
  lightVariant,
}: {
  handleOpenSignin: () => void;
  lightVariant?: string;
}) => {
  const ref = useRef(null);
  const currentPath = useLocation();
  const [scrolling, setScrolling] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const navigate = useNavigate();
  const { userDetails } = useAuthenticatedUser();

  // console.log(currentPath, "Currentpath");
  // console.log(userDetails, "userdetaialal");

  const queryClient = useQueryClient();
  const [modalState, setModalState] = useState<boolean>(false);

  const [openForm, setOpenFom] = useState("");

  const { openModal, setOpenModal }: any = useContext(LayoutContext);

  const [searchParams] = useSearchParams();
  const [term, setTerm] = useState("");

  const handleSearch = () => {
    const params = new URLSearchParams(searchParams);

    if (term) {
      params.set("searchTerm", term);
    } else {
      params.delete("searchTerm");
    }

    navigate(`${"/search"}?${params.toString()}`, {
      replace: true,
    });

    setTerm("");
  };

  const handleChange = (term: string) => {
    setTerm(term);
  };

  const nameParts = userDetails?.data?.fullname?.split(" ");
  const firstName = nameParts?.[0];

  const handleModalClick = (modalName: string): void => {
    openModal();
    setOpenModal(modalName);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 3);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogOut = () => {
    queryClient.clear();
    logOut(() => navigate(routes.HOME.path, { replace: true }));
    setModalState(false);
  };

  const isLinkActive = (href: string) => currentPath?.pathname === href;
  const textColor = lightVariant
    ? scrolling
      ? "text-bundo-black-a"
      : "text-bundo-white"
    : "";
  const logoColor =
    lightVariant || scrolling
      ? ""
      : lightVariant
        ? "[&>g>path]:fill-bundo-white"
        : "";

  const navItems: any = [
    {
      title: "My Profile",
      icon: "",
      path: "/profile",
    },
    {
      title: "Favourite",
      icon: "",
      path: "/favourite",
    },
    {
      title: "Messages",
      icon: "",
      path: "/chat",
    },
    {
      title: "Help",
      icon: "",
      path: "",
    },

    {
      title: "Settings",
      icon: "",
      path: "/profile",
    },
    // { title: "Get Help", icon: <SupportIcon />, path: "" },

    // {
    //   title: "App Information",
    //   icon: <ChatInfoIcon />,
    //   path: "",
    // },

    // { title: "Delete Account ", icon: <DeleteAccountIcon />, path: "" },
    {
      title: "LOG OUT",
      icon: "",
      path: routes.HOME.path,
      onClick: async () => {
        await logOut(() => navigate(routes.HOME.path, { replace: true }));
      },
    },
  ];

  return (
    <>
      <header
        className={`fixed w-full bg-white ${
          scrolling ? "shadow-md" : ""
        } z-50 duration-300 ease-in-out px-6 xlg:px-0  w-full flex justify-center`}
      >
        {/* Desktop Navbar */}
        <div className="w-full hidden lg:block">
          <nav className="container hidden  py-3.5 lg:block">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-12">
                <Link to="/">
                  <Logo />
                </Link>
                <div className="flex items-center  gap-6">
                  <Link
                    to="/search"
                    className="text-sm flex items-center gap-2"
                  >
                    <img src="/digital.png" alt="" />
                    Digital Services
                  </Link>
                  <Link
                    to="/search"
                    className="text-sm flex items-center gap-2"
                  >
                    <img src="/home-service.png" alt="" />
                    Home Services
                  </Link>
                </div>
              </div>
              <div
                className="relative flex items-center gap-10 font-light"
                ref={ref}
              >
                {["/"].map((path) => (
                  <Link
                    key={path}
                    to={path}
                    className={`text-sm hover:text-primary-500
                 ${
                   isLinkActive(path) ? "font-bold text-primary-500" : textColor
                 }`}
                  >
                    {
                      path === "/"
                        ? "Home"
                        : path.substring(1).replace("english", "English")
                      // .replace("recent-jobs", "My Jobs")
                      // .replace("provider", "Become a  Provider")
                    }
                  </Link>
                ))}
                {/* <div className=" flex items-center gap-4">
              cart
              <Link to={""}>
            
              </Link>
            </div> */}

                {!userDetails?.data?.service_profile ? (
                  <Link
                    to={"/provider"}
                    className={`text-sm hover:text-primary-500
                 ${
                   isLinkActive("/provider")
                     ? "font-bold text-primary-500"
                     : textColor
                 }`}
                  >
                    Become a Provider
                  </Link>
                ) : null}
                {userDetails?.data?.fullname ||
                userDetails?.data?.service_profile ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        border: "1px solid #94A3B8",
                        padding: "3px",
                        borderRadius: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => setModalState(!modalState)}
                    >
                      <Avatar
                        src={userDetails?.data?.profile_picture || ""}
                        fallbackText={userDetails?.data?.fullname}
                        className="text-black font-medium"
                        size={40}
                      />
                      <span style={{ fontWeight: 600 }}>{firstName || ""}</span>{" "}
                      <img
                        src={SelectIcon}
                        alt="select icon"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                      />
                    </div>
                    <div
                      // onClick={() => {
                      //   removeToken();
                      //   queryClient.clear();
                      // }}
                      id="dropdown"
                      className={`z-10 ${
                        modalState ? "block" : "hidden"
                      }  bg-white divide-y divide-gray-100 right-0 absolute rounded-lg shadow-lg w-44 dark:bg-gray-700`}
                    >
                      <ul
                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownDefaultButton"
                      >
                        {navItems?.map((v: any) => (
                          <Link to={v.path} key={v.path}>
                            <li
                              onClick={() => {
                                setModalState(false);
                                v.onClick && v.onClick();
                              }}
                            >
                              <span className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                {v.title}
                              </span>
                            </li>
                          </Link>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-4">
                    <Button
                      size="sm"
                      className="text-sm font-bold px-8"
                      onClick={() => setOpenFom("login")}
                    >
                      Login
                    </Button>
                    <Button
                      size="sm"
                      className="text-sm font-bold px-8"
                      onClick={() => setOpenFom("signup")}
                    >
                      Signup
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </nav>
          <div className="w-full relative inset-0 border shadow-sm px-[-60px] hidden lg:block" />

          <nav className="container hidden  py-2 lg:block">
            <div className="flex items-center w-full ">
              <div
                className=" flex items-center w-full justify-between font-light "
                ref={ref}
              >
                {["/"].map((path) => (
                  <div
                    key={path}
                    onClick={() => navigate("/search")}
                    // size="sm"
                    className={`text-sm w-fit cursor-pointer  hover:text-primary-500 py-2 px-2 rounede-lg

                 ${
                   isLinkActive(path)
                     ? "font-bold text-primary-500 bg-blue-600 text-white rounded-md"
                     : textColor || "mx-0 px-0 text-[#262626]"
                 }`}
                    // variant={isLinkActive(path) ? "default" : "plain"}
                  >
                    {path === "/" ? (
                      <div className="flex items-center gap-2">
                        <img src="/hambuger.png" />
                        <span>Browse Categories</span>
                      </div>
                    ) : (
                      path
                        .substring(1)
                        .replace("content-writing", "Content Writing")
                        .replace("graphics-design", "Graphics & Design")
                        .replace("programming", "Programming")
                        .replace("digital-marketing", "Digital Marketing")
                        .replace("digital-marketing", "Digital Marketing")
                        .replace("home-improvement", "Home Improvement")
                        .replace("event-planning", "Event Planning")
                        .replace(
                          "cleaning-housekeeping",
                          "Cleaning & Housekeeping"
                        )
                        .replace("personal-care", "Personal Care")
                    )}
                  </div>
                ))}
              </div>
              {currentPath?.pathname === "/search" ? (
                <div className="group mt-2 flex h-[53px]  items-center gap-2 rounded-[5px] border border-[#1E293B]   pl-[10px] lg:w-full xlg:w-full">
                  <Input
                    // onChange={handleSearch}
                    // onBlur={() => navigate("/search")}
                    placeholder="What service are you looking for today?."
                    className="h-full border-none bg-transparent text-xs font-normal outline-none"
                    onChange={(e) => handleChange(e.target.value)}
                  />
                  <span
                    onClick={handleSearch}
                    className="flex items-center bg-blue-600 p-4 justify-self-end rounded-lg"
                  >
                    <img src="/search-normal.png" alt="" />
                  </span>
                </div>
              ) : null}
            </div>
          </nav>
          <div className="w-full relative inset-0 border shadow-sm px-[-60px] hidden lg:block" />
        </div>

        {/* Mobile Navbar */}
        <nav
          className={`container  relative isolate   py-5 lg:hidden ${
            showMobileNav
              ? "bg-tertiary-pale-100"
              : scrolling
                ? "bg-tertiary-white-100 "
                : "bg-transparent"
          }`}
        >
          <div className="z-1 flex w-full items-center justify-between">
            <Link to="/">
              <img src={aLlogo} alt="contain" />
            </Link>
            <div className="flex flex-row-revers gap-4">
              {!userDetails?.data?.fullname && (
                <div className="flex gap-4">
                  <Button
                    size="sm"
                    className="text-sm font-bold px-8"
                    onClick={() => setOpenFom("login")}
                  >
                    Login
                  </Button>
                  <Button
                    size="sm"
                    className="text-sm font-bold px-8"
                    onClick={() => setOpenFom("signup")}
                  >
                    Sign up
                  </Button>
                </div>
              )}

              {userDetails?.data?.fullname && (
                <button onClick={() => setShowMobileNav(!showMobileNav)}>
                  {showMobileNav ? (
                    <div>
                      <X />
                    </div>
                  ) : (
                    <div>
                      <HamburgerMenu />
                    </div>
                  )}
                </button>
              )}
            </div>
          </div>
          <div
            className={`fixed left-0 grid w-full overflow-hidden bg-gray-300 px-6 text-center transition-all duration-300 ease-in-out ${
              showMobileNav
                ? "top-[72px] h-screen grid-rows-[1fr] py-10 opacity-100"
                : "top-[-60px] h-0 grid-rows-[0fr] opacity-0"
            }`}
          >
            <div className="overflow-scroll hideScrollBar">
              {userDetails?.data?.fullname ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      border: "1px solid #94A3B8",
                      padding: "3px",
                      borderRadius: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => setModalState(!modalState)}
                  >
                    <Avatar
                      src={userDetails?.data?.profile_picture || ""}
                      fallbackText={userDetails?.data?.fullname}
                      className="text-black font-medium"
                      size={40}
                    />
                    <span style={{ fontWeight: 600 }}>{firstName || ""}</span>{" "}
                    {/* <img
                      src={SelectIcon}
                      alt="select icon"
                      style={{ marginRight: "10px", cursor: "pointer" }}
                    /> */}
                  </div>
                  <div
                    // onClick={() => {
                    //   removeToken();
                    //   queryClient.clear();
                    // }}
                    id="dropdown"
                    className={`z-10 ${
                      modalState ? "block" : "hidden"
                    }  bg-white divide-y divide-gray-100 right-0 absolute rounded-lg shadow-lg w-44 dark:bg-gray-700`}
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      {navItems?.map((v: any) => (
                        <Link to={v.path} key={v.path}>
                          <li
                            onClick={() => {
                              setModalState(false);
                              v.onClick && v.onClick();
                            }}
                          >
                            <span className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                              {v.title}
                            </span>
                          </li>
                        </Link>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="flex gap-4 items-center justify-center">
                  <Button
                    size="sm"
                    className="text-sm font-bold px-8"
                    onClick={() => setOpenFom("login")}
                  >
                    Log in to your account
                  </Button>
                </div>
              )}

              {/* {userDetails?.data?.fullname && <MobileFilter />} */}
              <div className="flex flex-col gap-y-10">
                {userDetails?.data?.fullname ? (
                  <div className="flex w-full flex-col items-center gap-4">
                    {/* <Link to="" className="flex items-center gap-1">
                      <div className="isolate ml-1 flex aspect-square w-[clamp(50px,_8vw,_50px)] items-center justify-center rounded-full bg-[#34A853]">
                        <span className="font-bold text-[#fff]">
                          {userDetails?.data?.fullname[0]}
                        </span>
                      </div>
                      <span className="text-[12px] font-bold">
                        {userDetails?.data?.fullname}
                      </span>
                    </Link> */}

                    <Link
                      to="/search"
                      className="mt-4"
                      onClick={() => setShowMobileNav(false)}
                    >
                      Browse Categories
                    </Link>
                    <Button
                      className="w-full"
                      onClick={() => {
                        // logOut();
                        setShowMobileNav(false);
                      }}
                    >
                      Logout
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </nav>
      </header>

      <LoginForm openLogin={openForm} setOpenLogin={setOpenFom} />

      <SignUp
        openSignupForm={openForm}
        setOpenSignUp={setOpenFom}
        // handleOpenLoginForm={handleOpenLogin}
        // setOpenConfirmPassword={setOpenConfirmPassword}
        // handleOpenLogin={setOpenLogin}
      />

      <ForgotPassword
        openForgetPassword={openForm}
        setOpenForgetPassword={setOpenFom}
        // handleOpenLogin={handleOpenLogin}
      />
    </>
  );
};
