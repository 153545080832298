import * as yup from "yup";

const phoneRegExp = /(?:\+234|0)\d{10}$/;

export const profileSchema = yup.object().shape({
  name: yup.string().trim().required("Business name is required "),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup.string().required("Password is required"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Invalid phone number.")
    .typeError("Required"),
  country: yup.string().trim().required("Country is required"),
  city: yup.string().trim().required("City is required"),
  state: yup.string().trim().required("State is required"),
  zipCode: yup.string().trim().required("Zip code is required"),
  pictures: yup.mixed().required("Picture is required"),
});
