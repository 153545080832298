// import { SelectOptionBtn } from "ui/categoriesSort";

export const OtherServices = () => {
  return (
    <div className="container w-full mb-8 gap-3 flex flex-col justify-center  px-8   ">
      <span className=" text-center text-[24px] font-bold">
        You might be interested in some other services
      </span>
      <div className="w-full flex flex-wrap items-center justify-center gap-3">
        {/* {Array(35)
          .fill(0)
          .map(() => (
            <SelectOptionBtn
              text={"Web Design"}
              className="bg-[#e6e9ed] text-black border border-[#CBD5E1]"
              disabled={true}
            />
          ))} */}
      </div>
    </div>
  );
};
