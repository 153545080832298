import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getSkill } from "services";
import { notify } from "shared";
import { useFormStore } from "store";
import { Button } from "ui/button";
import { FormTextInput } from "ui/formTextInput";

export const SkillsForm = ({
  onSubmit,
  onBack,
}: {
  onSubmit: () => void;
  onBack: () => void;
}) => {
  const methods = useForm();
  const [term, setTerm] = useState("");
  const [amount, seAmount] = useState("");

  const selected = useFormStore((state) => state.selected);
  const setSelected = useFormStore((state: any) => state.setSelected);

  const [selectedSkill, setSelectedSkill] = useState<
    {
      cost: number;
      time_frame: string;
      skills: string;
    }[]
  >([]);

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["service"],
    queryFn: () => getSkill(term),
    enabled: !!term,
  });

  const handleSelectSkill = (term: string, amount: number) => {
    setSelectedSkill((prev) => [
      ...prev,
      {
        cost: amount,
        time_frame: "FIXED",
        skills: term,
      },
    ]);
  };

  useEffect(() => {
    if (term) {
      refetch();
    }
  }, [term, refetch]);
  const onFormSubmit = (data: any) => {
    // Handle skill selection and validation

    setSelected({
      ...selected,
      data: { ...selected, skills: selectedSkill },
    });
    onSubmit(); // Move to next step
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          className="grid grid-cols-[1fr_1fr_auto] gap-6 pb-10"
          onSubmit={methods.handleSubmit(onFormSubmit)}
        >
          <FormTextInput
            name="skills"
            placeholder="Search to add a skill..."
            onChange={(e) => setTerm(e.target.value)}
          />

          <div className="flex fixed bottom-0 left-0 right-0 gap-4 h-[80px] bg-white justify-end border-t border-gray-200">
            <div className="flex items-center w-full justify-end gap-4 container">
              <Button
                variant="light-blue"
                className="w-fit px-10"
                onClick={onBack}
              >
                Back
              </Button>
              <Button
                variant="default"
                size="sm"
                className="w-fit px-14"
                type="submit"
              >
                Next
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>

      {data && data?.results.length >= 1 ? (
        <div className="flex flex-col gap-4">
          {data?.results?.map((item: any) => (
            <div
              key={item?.id}
              className="flex gap-2 justify-between items-center bg-[#ededed] px-4 py-2 rounded-lg"
            >
              <span className="">{item?.name}</span>

              <div className="flex  justify-end items-center gap-2 basis-1/2">
                <div className="flex gap-1 items-center">
                  <span>₦</span>
                  <input
                    placeholder="amount "
                    // onChange={(e) => setTerm(e.target.value)}
                    onChange={(e) => seAmount(e.target.value)}
                    className=" bg-gray-50 ring-1 h-[50px] border outline-none border-none   border-[#E2E8F0] placeholder:text-[#AAAAAA] placeholder:font-normal placeholder:text-xs ring-[#E2E8F0] transition-all duration-300 ease-in-out text-sm rounded-lg block w-full dark:bg-gray-700 px-2 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 min-w-[100px] "
                  />
                </div>

                <Button
                  className="min-w-[50px]"
                  onClick={() => {
                    handleSelectSkill(item.id, +amount);

                    notify.success({
                      subtitle: "Successfully created",
                      message: "Item added",
                    });
                  }}
                  // disabled={!!amount}
                >
                  Add
                </Button>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};
