import { FC } from "react";

import {
  Blog,
  Explore,
  FAQ,
  Footer,
  HeroSection,
  LogosCarousel,
  OtherServices,
} from "@component";

interface HomepageProps {
  prop1?: string
}
export const Homepage: FC<HomepageProps> = () => {
  return (
    <>
      {/* check home page */}
      <HeroSection />
      <LogosCarousel />
      <Explore />
      <FAQ />
      <Blog />
      <OtherServices />
      

      <Footer prop1={"string"} />
    </>
  );
};
